import {
  faAngleUp,
  faAngleDown,
  faGripDotsVertical,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useEffect } from "react";
import Button from "../buttons/Button";

const Collapse = ({
  title,
  variant = "primary",
  isDroppable = false,
  className,
  initialStateOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(initialStateOpen);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current)
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
  }, [isOpen]);

  return (
    <div className={`collapse ${className || ""}`}>
      <div
        className={`collapse-header cursor-pointer bg-${variant}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="p1 flex gap-1 align-center">
          {isDroppable && (
            <FontAwesomeIcon
              className="text-dark"
              transform={{ y: 1 }}
              icon={faGripDotsVertical}
            />
          )}
          <span>{title}</span>
        </div>
        <Button link onClick={() => setIsOpen(!isOpen)}>
          <FontAwesomeIcon
            className="text-dark"
            size="xl"
            transform={{ y: 2 }}
            icon={isOpen ? faAngleUp : faAngleDown}
          />
        </Button>
      </div>
      <div ref={contentRef} className="collapse-body">
        {children}
      </div>
    </div>
  );
};

export default Collapse;
