import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse";

export const downloadExcel = (data, name) => {
  const timestamp = Date.now();
  const cleanData = data.map(getTransformationFunction(name));
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(cleanData);
  XLSX.utils.book_append_sheet(workbook, worksheet, `${name}-${timestamp}`);
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  saveAs(
    new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    }),
    `${name}.xlsx`
  );
};

export const downloadCsv = (data, name) => {
  console.log(data);
  const timestamp = Date.now();
  const csvData = Papa.unparse(data.map(getTransformationFunction(name)));
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
  saveAs(blob, `${name}-${timestamp}.csv`);
};

export const downloadPdf = (data, name) => {
  const timestamp = Date.now();
  const blob = new Blob([data], { type: "application/pdf;charset=utf-8" });
  saveAs(blob, `${name}-${timestamp}.pdf`);
};

export const copyToClipboard = (text, toastMsg = null) => {
  navigator.clipboard.writeText(text);
  if (toastMsg) toast.success(toastMsg);
};

const dishDataTransform = ({
  typeId,
  categoryId,
  mainDish,
  imagePath,
  restaurant,
  restaurantId,
  ...rest
}) => ({
  ...rest,
  allergies: rest.allergies.map((allergy) => allergy.label).join(", "),
  foodtags: rest.foodtags.map((foodtag) => foodtag.label).join(", "),
  dishtype: rest.dishtype.label,
  dishcategory: rest.dishcategory?.label,
});

const menuDataTransform = ({ imagePath, restaurantId, ...rest }) => ({
  ...rest,
  dishes: rest.dishes.map((dish) => dish.name).join(", "),
  foodtags: rest.foodtags.map((foodtag) => foodtag.label).join(", "),
});

const todaysSpecialDataTransform = ({ imagePath, restaurantId, ...rest }) => ({
  ...rest,
  dishes: rest.dishes.map((dish) => dish.name).join(", "),
  foodtags: rest.foodtags.map((foodtag) => foodtag.label).join(", "),
});

const dataTransformationMapping = {
  dishes: dishDataTransform,
  menus: menuDataTransform,
  todaysSpecials: todaysSpecialDataTransform,
};

const getTransformationFunction = (name) => {
  const transformationFunction = dataTransformationMapping[name];
  if (!transformationFunction) return (item) => item;
  return transformationFunction;
};
