import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../utils/Alert";

function StepRestaurantPricing({
  tKey = "register.stepPricing.",
  currentForm,
  setPricingDetails,
  setIsValid,
  setCurrentPrice = () => {},
}) {
  const [selectedPricing, setSelectedPricing] = useState(
    currentForm.selectedPricing || null
  );
  const [requireTablet, setRequireTablet] = useState(
    currentForm.requireTablet || false
  );
  const { t } = useTranslation();

  const handlePricingSelection = (value) => {
    setSelectedPricing(value);
    setPricingDetails({
      ...currentForm,
      selectedPricing: value,
    });
  };

  const handleTabletSelection = (value = false) => {
    setRequireTablet(value || false);
    setPricingDetails({
      ...currentForm,
      requireTablet: value || false,
    });
  };

  useEffect(() => {
    setIsValid(!!selectedPricing || !!requireTablet);
  }, [selectedPricing, setIsValid, requireTablet]);

  const tabletPrice = 350;

  const pricingOptions = [
    {
      value: 0,
      key: "basic",
    },
    {
      value: 0,
      oldValue: 650,
      key: "plus",
    },
    {
      value: 0,
      oldValue: 650,
      key: "premium",
    },
  ];

  useEffect(() => {
    setCurrentPrice(
      (selectedPricing?.value || 0) + (requireTablet ? tabletPrice : 0)
    );
  }, [selectedPricing, requireTablet, setCurrentPrice]);

  return (
    <div className="register-step mb-4">
      <div className="step-header">
        <h2>{t(`${tKey}sidebar`)}</h2>
      </div>
      <div className="btn-group-vertical gap-1 flex-grow">
        {pricingOptions.map((option) => (
          <>
            <input
              type="radio"
              id={`option-${option.key}`}
              name="radio-group"
              className="btn-radio"
              checked={selectedPricing === option}
              onChange={() => handlePricingSelection(option)}
            />
            <label
              htmlFor={`option-${option.key}`}
              className={`btn btn-label btn-tertiary flex column justify-start align-start p-3 col-4 text-wrap ${
                selectedPricing?.key === option.key ? "btn-checked-accent" : ""
              }`}
            >
              <h5 className="flex w-100 justify-between mt-0 mb-2">
                <span>{t(`${tKey}${option.key}.title`)}</span>
                <div>
                  {option.oldValue && (
                    <span className="text-light p3 mr-3">
                      <del>{option.oldValue}CHF</del>
                    </span>
                  )}
                  <span className={`${!!option.oldValue ? "text-danger" : ""}`}>
                    {option?.value === 0
                      ? t(`${tKey}free`)
                      : `${option?.value}CHF`}
                  </span>
                  {option.key === "premium" && (
                    <span> {t(`${tKey}quote`)}</span>
                  )}
                </div>
              </h5>
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: t(`${tKey}${option.key}.text`),
                }}
              />
            </label>
          </>
        ))}
      </div>
      <Alert variant="pastel-info" className="mt-4 mb-2">
        <span
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: t(`${tKey}tablet.description`) }}
        />
      </Alert>
      <div className="btn-group-horizontal gap-1">
        <input
          type="radio"
          id={`option-with-tablet`}
          name="radio-group"
          className="btn-radio"
          checked={requireTablet === true}
          onChange={() => handleTabletSelection(true)}
        />
        <label
          htmlFor={`option-with-tablet`}
          className={`btn btn-label btn-tertiary flex column align-start p-3 col-6 ${
            requireTablet ? "btn-checked-accent" : ""
          }`}
        >
          <div className="flex justify-between w-100">
            <span>{t(`${tKey}tablet.with`)}</span>
            <span className="bold">{tabletPrice}CHF</span>
          </div>
        </label>
        <input
          type="radio"
          id={`option-without-tablet`}
          name="radio-group"
          className="btn-radio"
          checked={requireTablet === false}
          onChange={() => handleTabletSelection(false)}
        />
        <label
          htmlFor={`option-without-tablet`}
          className={`btn btn-label btn-tertiary flex column align-start p-3 col-6 ${
            !requireTablet ? "btn-checked-accent" : ""
          }`}
        >
          {t(`${tKey}tablet.without`)}
        </label>
      </div>
    </div>
  );
}

export default StepRestaurantPricing;
