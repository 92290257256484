import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../inputs/Form";
import Modal from "../../../utils/Modal";
import Alert from "../../../utils/Alert";
import useApi from "../../../../hooks/useApi";
import useModal from "../../../../hooks/useModal";
import { putInit2FA, putSetup2FA } from "../../../../services/authService";
import OTPInput from "react-otp-input";
import { isValidEmail } from "../../../../utils/formatting";

const UserAdmin = ({
  tKey = "dashboard.settings.userAdmin.",
  data = null,
  onFormDataChange,
}) => {
  const { t } = useTranslation();
  const [userFields, setUserFields] = useState([]);
  const [credentialFields, setCredentialFields] = useState([]);
  const [userData, setUserData] = useState({ userId: data.userId });
  const [credentialData, setCredentialData] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [twoFAModalContent, setTwoFAModalContent] = useState(null);
  const { isModalOpen, openModal, closeModal } = useModal();
  const { request: putInit2FARequest } = useApi(putInit2FA);
  const { request: putSetup2FARequest } = useApi(putSetup2FA);

  const wrapCloseModal = useCallback(
    (outcome = false) => {
      closeModal();
      setCredentialData((prev) => ({ ...prev, is2FA: outcome || false }));
    },
    [closeModal, setCredentialData]
  );

  const handle2FAChange = useCallback(
    (value) => {
      if (value) {
        const userId = userData.userId;
        putInit2FARequest(userId)
          .then((res) => {
            setTwoFAModalContent(
              <ModalContent
                qr={res.data.qr}
                tKey={tKey}
                onOtpCompleted={(otp) => {
                  return new Promise((resolve, reject) => {
                    putSetup2FARequest(userId, otp)
                      .then(() => {
                        resolve();
                      })
                      .catch((err) => {
                        console.log(err);
                        reject(err);
                      });
                  });
                }}
                closeModal={wrapCloseModal}
              />
            );
            openModal();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [
      userData.userId,
      putInit2FARequest,
      putSetup2FARequest,
      tKey,
      openModal,
      wrapCloseModal,
    ]
  );

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const newManagerData = {};
      const newCredentialData = {};

      userFields.forEach((field) => {
        if (data.hasOwnProperty(field.name))
          newManagerData[field.name] = data[field.name];
      });

      credentialFields.forEach((field) => {
        if (data.hasOwnProperty(field.name))
          newCredentialData[field.name] = data[field.name];
      });

      setUserData(newManagerData);
      setCredentialData(newCredentialData);
    }
  }, [data, userFields, credentialFields]);

  useEffect(() => {
    setUserFields([
      {
        name: "firstname",
        label: t(`${tKey}firstname.label`),
        placeholder: t(`${tKey}firstname.placeholder`),
        type: "text",
        class: "w-100",
        validate: (value) => {
          if (!value) return t(`${tKey}errors.firstname.required`);
          if (value.length < 3) return t(`${tKey}errors.firstname.min`);
          if (value.length > 50) return t(`${tKey}errors.firstname.max`);
          return "";
        },
      },
      {
        name: "lastname",
        label: t(`${tKey}lastname.label`),
        placeholder: t(`${tKey}lastname.placeholder`),
        type: "text",
        class: "w-100",
        validate: (value) => {
          if (!value) return t(`${tKey}errors.lastname.required`);
          if (value.length < 3) return t(`${tKey}errors.lastname.min`);
          if (value.length > 50) return t(`${tKey}errors.lastname.max`);
          return "";
        },
      },
      {
        name: "phone",
        label: t(`${tKey}phone.label`),
        placeholder: t(`${tKey}phone.placeholder`),
        type: "phone",
        class: "w-100",
        validate: (value) => {
          if (!value) return t(`${tKey}errors.phone.required`);
          if (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(value))
            return t(`${tKey}errors.phone.invalid`);
          return "";
        },
      },
      {
        name: "imagePath",
        label: t(`${tKey}picture.label`),
        placeholder: t(`${tKey}picture.placeholder`),
        type: "file",
        class: "w-100",
      },
      {
        name: "occupiedFunction",
        label: t(`${tKey}occupiedFunction.label`),
        placeholder: t(`${tKey}occupiedFunction.placeholder`),
        type: "text",
        class: "w-100",
        validate: (value) => {
          if (value.length > 50) return t(`${tKey}errors.occupiedFunction.max`);
          return "";
        },
      },
      {
        name: "userId",
        type: "hidden",
      },
    ]);
    setCredentialFields([
      {
        name: "email",
        label: t(`${tKey}email.label`),
        placeholder: t(`${tKey}email.placeholder`),
        type: "email",
        class: "w-100",
        validate: (value) => {
          if (!value) return t(`${tKey}errors.email.required`);
          if (!isValidEmail(value)) return t(`${tKey}errors.email.invalid`);
          return "";
        },
      },
      {
        name: "is2FA",
        label: t(`${tKey}is2FA.label`),
        type: "checkbox",
        class: "w-100",
        info: t(`${tKey}is2FA.info`),
        beforeInputChange: handle2FAChange,
      },
    ]);
  }, [t, tKey, handle2FAChange]);

  useEffect(() => {
    onFormDataChange(isValid ? { ...userData, ...credentialData } : null);
  }, [userData, credentialData, isValid, onFormDataChange]);

  return (
    <>
      <div className="dashboard-content flex gap-2">
        <div className="w-50 bg-white rounded p-3">
          <Form
            fields={userFields}
            currentForm={userData}
            setFormDetails={setUserData}
            setIsValid={setIsValid}
          />
        </div>
        <div className="w-50 bg-white rounded p-3 flex column">
          <Form
            fields={credentialFields}
            currentForm={credentialData}
            setFormDetails={setCredentialData}
            setIsValid={setIsValid}
          />
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={wrapCloseModal} noFooter>
        {twoFAModalContent}
      </Modal>
    </>
  );
};

function ModalContent({ qr, tKey, onOtpCompleted, closeModal }) {
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const handleOtpCompletion = useCallback(
    async (otp) => {
      await onOtpCompleted(otp)
        .then(() => {
          closeModal(true);
        })
        .catch((err) => {
          console.log(err);
          setError(err.error);
        });
    },
    [onOtpCompleted, closeModal]
  );

  useEffect(() => {
    if (otp.length === 6) handleOtpCompletion(otp);
  }, [otp, handleOtpCompletion]);

  return (
    <div className="modal-body flex">
      <div className="flex w-50 align-center justify-center">
        <img src={qr} alt="QR Code" width={"80%"} />
      </div>
      <div className="w-50">
        <h2>{t(`${tKey}2FAModal.title`)}</h2>
        <p dangerouslySetInnerHTML={{ __html: t(`${tKey}2FAModal.info`) }} />
        {error && (
          <Alert className="mb-2" variant="danger">
            {error}
          </Alert>
        )}
        <OTPInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          separator={<span></span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={"otp-input"}
          containerStyle={"otp-container"}
        />
      </div>
    </div>
  );
}

export default UserAdmin;
