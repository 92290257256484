import React from "react";
import { useTranslation } from "react-i18next";

function Tag({
  tag = null,
  text = null,
  round = false,
  variant = "dark",
  tKey = "",
}) {
  const { t } = useTranslation();
  if (!tag && !text) return null;
  return (
    <div>
      <span className={`tag tag-${variant} ${round ? "tag-round" : ""}`}>
        {t(`${tKey}${tag ? tag.label : text}`)}
      </span>
    </div>
  );
}

export default Tag;
