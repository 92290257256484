import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getCompanyForm,
  updateCompanyForm,
  confirmCompany,
  declineCompany,
} from "../../../../services/admin/companyService";
import { getListOptions } from "../../../../services/listService";
import useApi from "../../../../hooks/useApi";
import Spinner from "../../../utils/Spinner";
import Form from "../../../inputs/Form";
import Button from "../../../buttons/Button";
import { toast } from "react-toastify";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isValidEmail } from "../../../../utils/formatting";
import useLangNavigate from "../../../../hooks/useLangNavigate";

const CompanyFormView = ({ tKey = "dashboard.companyFormView." }) => {
  const { t } = useTranslation();
  const { request: getCompanyFormRequest, loading: getCompanyFormLoading } =
    useApi(getCompanyForm);
  const { request: updateCompanyFormRequest } = useApi(updateCompanyForm);
  const { request: confirmCompanyRequest } = useApi(confirmCompany);
  const { request: declineCompanyRequest } = useApi(declineCompany);
  const { companyId } = useParams();
  const [citiesList, setCitiesList] = useState([]);
  const [company, setCompany] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const formTKey = "register.stepCompany.";
  const navigate = useLangNavigate();

  useEffect(() => {
    getCompanyFormRequest(companyId)
      .then((res) => {
        setCompany(res.data);
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.message);
        navigate("/dashboard/admin-company-forms");
      });
  }, [t, tKey, companyId, getCompanyFormRequest, setCompany, navigate]);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const cities = await getListOptions("city");
        setCitiesList(cities.data);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    };
    fetchListData();
  }, []);

  const handleChange = useCallback(
    (data) => {
      setCompany((prev) => ({ ...prev, ...data }));
    },
    [setCompany]
  );

  const handleSaveChange = useCallback(
    (data) => {
      updateCompanyFormRequest(data)
        .then((res) => {
          setCompany(res.data);
        })
        .catch((error) => {
          toast.error(error.message);
          console.error(error);
        });
    },
    [updateCompanyFormRequest, setCompany]
  );

  const fields = useMemo(() => {
    if (citiesList && citiesList.length > 0)
      return [
        {
          name: "companyName",
          placeholder: t(`${formTKey}companyName.placeholder`),
          type: "text",
          class: "w-100",
          validate: (value) => {
            if (!value) return t(`${formTKey}errors.name.required`);
            if (value.length < 2) return t(`${formTKey}errors.name.min`);
            if (value.length > 50) return t(`${formTKey}errors.name.max`);
            return "";
          },
        },
        {
          name: "firstName",
          label: t(`${formTKey}firstname.label`),
          placeholder: t(`${formTKey}firstname.placeholder`),
          type: "text",
          class: "col-6 pr-2",
          disabled: true,
          validate: (value) => {
            if (!value) return t(`${formTKey}errors.firstname.required`);
            if (value.length < 2) return t(`${formTKey}errors.firstname.min`);
            if (value.length > 50) return t(`${formTKey}errors.firstname.max`);
            return "";
          },
        },
        {
          name: "lastName",
          label: t(`${formTKey}lastname.label`),
          placeholder: t(`${formTKey}lastname.placeholder`),
          type: "text",
          class: "col-6 pl-2",
          disabled: true,
          validate: (value) => {
            if (!value) return t(`${formTKey}errors.lastname.required`);
            if (value.length < 2) return t(`${formTKey}errors.lastname.min`);
            if (value.length > 50) return t(`${formTKey}errors.lastname.max`);
            return "";
          },
        },
        {
          name: "city",
          label: t(`${formTKey}city.label`),
          placeholder: t(`${formTKey}city.placeholder`),
          type: "dropdown",
          class: "col-4 pr-2",
          options: citiesList,
          validate: (value) => {
            if (!value) return t(`${formTKey}city.required`);
            return "";
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `;
          },
        },
        {
          name: "address",
          label: t(`${formTKey}address.label`),
          placeholder: t(`${formTKey}address.placeholder`),
          type: "text",
          class: "col-8 pl-2",
          validate: (value) => {
            if (!value) return t(`${formTKey}address.required`);
            if (value.length < 2) return t(`${formTKey}address.min`);
            if (value.length > 50) return t(`${formTKey}address.max`);
            return "";
          },
        },
        {
          name: "email",
          label: t(`${formTKey}email.label`),
          placeholder: t(`${formTKey}email.placeholder`),
          type: "text",
          class: "col-6 pr-2",
          validate: (value) => {
            if (!value) return t(`${formTKey}email.required`);
            if (!isValidEmail(value)) return t(`${formTKey}email.invalid`);
            return "";
          },
        },
        {
          name: "phone",
          label: t(`${formTKey}phone.label`),
          placeholder: t(`${formTKey}phone.placeholder`),
          type: "phone",
          class: "col-6 pl-2",
          validate: (value) => {
            if (!value) return t(`${formTKey}phone.required`);
            if (!isValidPhoneNumber(value))
              return t(`${formTKey}phone.invalid`);
            return "";
          },
        },
        {
          name: "message",
          label: t(`${formTKey}message.label`),
          placeholder: t(`${formTKey}message.placeholder`),
          type: "textarea",
          class: "w-100",
          rows: 5,
          disabled: true,
          validate: (value) => {
            if (!value) return t(`${formTKey}message.required`);
            if (value.length < 2) return t(`${formTKey}message.min`);
            if (value.length > 50) return t(`${formTKey}message.max`);
            return "";
          },
        },
      ];
  }, [t, citiesList]);

  const handleConfirm = useCallback(
    (data) => {
      confirmCompanyRequest(data)
        .then(() => {
          toast.success(`Company form confirmed`);
          navigate("/dashboard/admin-company-forms");
        })
        .catch((error) => {
          toast.error(error.message);
          console.error(error);
        });
    },
    [confirmCompanyRequest, navigate]
  );

  const handleDecline = useCallback(
    (data) => {
      declineCompanyRequest(data)
        .then(() => {
          toast.success(`Company form declined`);
          navigate("/dashboard/admin-company-forms");
        })
        .catch((error) => {
          toast.error(error.message);
          console.error(error);
        });
    },
    [declineCompanyRequest, navigate]
  );

  if (getCompanyFormLoading || !citiesList.length) return <Spinner />;

  return (
    <div className="dashboard-content flex column">
      <div className="">
        <h2 className="m-0 flex justify-between">
          <span>{company?.companyName}</span>
          <div className="flex gap-1">
            <Button variant="primary" onClick={() => handleConfirm(company)}>
              {t(`utils.common.confirm`)}
            </Button>
            <Button variant="danger" onClick={() => handleDecline(company)}>
              {t(`utils.common.decline`)}
            </Button>
          </div>
        </h2>
        <Form
          fields={fields}
          currentForm={company}
          setFormDetails={handleChange}
          setIsValid={setIsValid}
          fieldClass="bg-white"
        />
        <Button
          variant="success"
          onClick={() => handleSaveChange(company)}
          disabled={!isValid}
        >
          {t(`utils.common.save`)}
        </Button>
      </div>
    </div>
  );
};

export default CompanyFormView;
