import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useLangNavigate = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const navigateWithLang = useCallback(
    (to, { newTab = false, ...options } = {}) => {
      const lang = i18n.language.split("-")[0];
      let newPath = "";

      if (typeof to === "string") {
        newPath = to.startsWith("/") ? `/${lang}${to}` : `/${lang}/${to}`;
      } else if (typeof to === "object" && to.pathname) {
        newPath = `/${lang}${
          to.pathname.startsWith("/") ? to.pathname : "/" + to.pathname
        }`;
        to = { ...to, pathname: newPath };
      } else {
        newPath = to;
      }

      newTab ? window.open(newPath, "_blank") : navigate(newPath, options);
    },
    [navigate, i18n]
  );

  return navigateWithLang;
};

export default useLangNavigate;
