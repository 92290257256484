import React from "react";
import { useTranslation } from "react-i18next";
import Form from "../../inputs/Form";
import { isValidEmail } from "../../../utils/formatting";

function StepRestaurantCredentials({
  currentForm,
  setCredentialsDetails,
  setIsValid = () => {},
}) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "email",
      label: t("register.step6.email.label"),
      placeholder: t("register.step6.email.placeholder"),
      type: "email",
      class: "w-100",
      validate: (value) => {
        if (!value) return t("register.errors.email.required");
        if (!isValidEmail(value)) return t("register.errors.email.invalid");
        return "";
      },
    },
    {
      name: "password",
      label: t("register.step6.password.label"),
      placeholder: t("register.step6.password.placeholder"),
      type: "password",
      class: "w-100",
      validate: (value) => {
        if (!value) return t("register.errors.password.required");
        if (
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
            value
          )
        )
          return t("register.errors.password.invalid");
        return "";
      },
      info: t("register.step6.password.info"),
    },
    {
      name: "confirmPassword",
      label: t("register.step6.confirmPassword.label"),
      placeholder: t("register.step6.confirmPassword.placeholder"),
      type: "password",
      class: "w-100",
      autoComplete: "off",
      validate: (value, form) => {
        if (!value) return t("register.errors.confirmPassword.required");
        if (!form || value !== form.password)
          return t("register.errors.confirmPassword.invalid");
        return "";
      },
    },
  ];

  const handleCredentialsDetails = (data) => {
    setCredentialsDetails(data);
  };

  return (
    <div className="register-step">
      <div className="step-header">
        <h2>{t("register.step6.title")}</h2>
      </div>
      <Form
        fields={fields}
        currentForm={currentForm}
        setFormDetails={handleCredentialsDetails}
        setIsValid={setIsValid}
      />
    </div>
  );
}

export default StepRestaurantCredentials;
