import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../buttons/Button";
import { useStripeDone } from "../../../../hooks/useStripeStatus";

const Bank = ({ tKey = "dashboard.settings.bank.", restaurantId }) => {
  const { t } = useTranslation();
  const { isStripeDone, stripeUrl } = useStripeDone(restaurantId);

  return (
    <div className="dashboard-content">
      <div className="w-100 h-100 bg-white rounded p-3 flex align-center justify-center">
        <Button
          variant="danger"
          onClick={() => window.open(stripeUrl, "_blank")}
        >
          {t(`${tKey}${isStripeDone ? "update" : "setup"}`)}
        </Button>
      </div>
    </div>
  );
};

export default Bank;
