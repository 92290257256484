import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { defaultStaticRanges } from "react-date-range/dist/defaultRanges";
import { useTranslation } from "react-i18next";
import * as locales from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Button from "./Button";
import { formatDate } from "../../utils/formatting";

function DatePickerButton({
  variant = "white",
  onDateRangeChange,
  startAt = null,
  endAt = null,
  maxDate = new Date(),
  minDate = null,
}) {
  const { i18n, t } = useTranslation();
  const [state, setState] = useState([
    {
      startDate: startAt ? new Date(startAt) : new Date(),
      endDate: endAt ? new Date(endAt) : null,
      key: "selection",
    },
  ]);
  const [isOpen, setIsOpen] = useState(false);

  const togglePicker = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (item) => {
    setState([item.selection]);
    if (
      item.selection.startDate &&
      item.selection.endDate &&
      item.selection.startDate.getTime() !== item.selection.endDate.getTime()
    ) {
      onDateRangeChange(item.selection);
      setIsOpen(false);
    }
  };

  const dateDisplay = `${formatDate(state[0].startDate, false)}${
    state[0].endDate ? ` - ${formatDate(state[0].endDate, false)}` : ""
  }`;
  const locale = locales[i18n.language] || locales.enUS;

  // Translation for the static ranges
  const staticRanges = defaultStaticRanges.map((range) => {
    return { ...range, label: t(range.label) };
  });

  // Check if maxDate and minDate are Date objects or strings
  const maxDateObj = maxDate instanceof Date ? maxDate : new Date(maxDate);
  const minDateObj = minDate instanceof Date ? minDate : new Date(minDate);

  return (
    <div className="date-picker-wrapper">
      <Button variant={variant} size="sm" onClick={togglePicker}>
        {state[0].startDate ? dateDisplay : t("utils.datePicker.selectDate")}
      </Button>
      {isOpen && (
        <>
          <div className="date-picker-backdrop" onClick={togglePicker} />
          <div className="date-picker">
            <DateRangePicker
              locale={locale}
              onChange={handleSelect}
              months={2}
              ranges={state}
              direction="horizontal"
              staticRanges={staticRanges}
              maxDate={maxDateObj}
              minDate={minDateObj}
              moveRangeOnFirstSelection={false}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DatePickerButton;
