import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../api/methodsCfg";

export const getTodaysSpecial = async (todaysspecialId) => {
  const response = await getRequest(`/todaysspecial/${todaysspecialId}`);
  return response;
};

export const getTodaysSpecials = async (restaurantId) => {
  const response = await getRequest(
    `/todaysspecial/byRestaurant/${restaurantId}`
  );
  return response;
};

export const deleteTodaysSpecial = async (todaysspecialId) => {
  const response = await deleteRequest(`/todaysspecial/${todaysspecialId}`);
  return response;
};

export const updateTodaysSpecialsOrder = async (restaurantId, ids) => {
  const response = await putRequest(`/todaysspecial/order/${restaurantId}`, {
    ids,
  });
  return response;
};

export const creatNewTodaysSpecial = async (payload) => {
  const cleanPayload = {
    name: payload.name,
    description: payload.description,
    isActive: payload.isActive || false,
    isPublished: payload.isPublished || false,
    price: payload.price,
    dishIds: [
      typeof payload.main.value === "object"
        ? payload.main.value.dishId
        : payload.main.value,
      typeof payload.starter?.value === "object"
        ? payload.starter.value.dishId
        : payload.starter?.value,
      typeof payload.dessert?.value === "object"
        ? payload.dessert.value.dishId
        : payload.dessert?.value,
    ].filter((id) => id != null),
    restaurantId: payload.restaurantId,
    from: payload.from,
    to: payload.to,
    isDailyRecurring: payload.isDailyRecurring,
    isWeeklyRecurring: payload.isWeeklyRecurring,
  };
  const response = await postRequest(`/todaysspecial`, cleanPayload);
  return response;
};

export const duplicateTodaysSpecial = async (payload) => {
  delete payload.todaysSpecialId;
  payload.isActive = false;
  payload.isPublished = false;
  payload.dishIds = payload.dishes.map((dish) => dish.dishId);
  const response = await postRequest(`/todaysspecial`, payload);
  return response;
};

export const updateTodaysSpecial = async (payload) => {
  console.log(payload);
  const cleanPayload = {
    name: payload.name,
    description: payload.description,
    isActive: payload.isActive || false,
    isPublished: payload.isPublished || false,
    price: payload.price,
    dishIds: [
      typeof payload.main.value === "object"
        ? payload.main.value.dishId
        : payload.main.value,
      typeof payload.starter?.value === "object"
        ? payload.starter.value.dishId
        : payload.starter?.value,
      typeof payload.dessert?.value === "object"
        ? payload.dessert.value.dishId
        : payload.dessert?.value,
    ].filter((id) => id != null),
    restaurantId: payload.restaurantId,
    order: payload.order,
    from: payload.from,
    to: payload.to,
    isDailyRecurring: payload.isDailyRecurring,
    isWeeklyRecurring: payload.isWeeklyRecurring,
  };
  const response = await putRequest(
    `/todaysspecial/${payload.todaysSpecialId}`,
    cleanPayload
  );
  return response;
};

export const switchActivateTodaysSpecial = async (todaysspecialId) => {
  const response = await putRequest(`/todaysspecial/active/${todaysspecialId}`);
  return response;
};
