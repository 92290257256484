import { getEnvVariable } from "../utils/config";
import axios from "axios";

const instance = axios.create({
  baseURL: getEnvVariable("REACT_APP_API_BASE_URL"),
});

// Add a request interceptor for token and lang
instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  config.headers["Accept-Language"] =
    localStorage.getItem("i18nextLng") || "en";
  return config;
});

export default instance;
