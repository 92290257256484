import { useState } from "react";
import Cookies from "js-cookie";

function useCookie(cookieName) {
  const [cookieValue, setCookieValue] = useState(Cookies.get(cookieName));

  const updateCookie = (newValue, options) => {
    Cookies.set(cookieName, newValue, options);
    setCookieValue(newValue);
  };

  return [cookieValue, updateCookie];
}

export default useCookie;
