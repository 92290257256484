import React from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import Button from "../buttons/Button";
import useLangNavigate from "../../hooks/useLangNavigate";
import useCookies from "../../hooks/useCookies";

const Cookie = ({ tKey = "utils.cookie." }) => {
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const [, setUserConsent] = useCookies("userConsent");

  const acceptOnlyRequiredCookies = () => {
    setUserConsent("required", { expires: 150 });
  };
  const acceptAllCookies = () => {
    console.log("onAccept called, setting cookie to 'all'");
    setUserConsent("all", { expires: 150 });
  };

  return (
    <CookieConsent
      location="bottom"
      cookieName={t(`${tKey}name`)}
      expires={150}
      buttonWrapperClasses="flex column gap-1 p-2 self-align-center"
      enableDeclineButton
      ButtonComponent={Button}
      customButtonProps={{ variant: "success", size: "sm" }}
      customDeclineButtonProps={{ variant: "info", size: "sm" }}
      onAccept={acceptAllCookies}
      onDecline={acceptOnlyRequiredCookies}
      buttonText={t(`${tKey}acceptAll`)}
      declineButtonText={t(`${tKey}acceptRequired`)}
    >
      {t(`${tKey}message`)}{" "}
      <Button link onClick={() => navigate("consentAndCookies")}>
        {t(`${tKey}privacyPolicy`)}
      </Button>
    </CookieConsent>
  );
};

export default Cookie;
