import { getRequest, putRequest } from "../../api/methodsCfg";

export const getAllUsers = async () => {
  const response = await getRequest("/user/all");
  return response;
};

export const getDevelopers = async () => {
  const response = await getRequest("/user/developers");
  return response;
};

export const getUsersCount = async () => {
  const response = await getRequest("/user/count");
  return response;
};

export const searchUsers = async (query) => {
  const response = await getRequest(`/user/searchByAdmin/${query}`);
  return response;
};

export const getUserDetails = async (userId) => {
  const response = await getRequest(`/user/details/${userId}`);
  return response;
};

export const updateUserDetails = async (userId, payload) => {
  const response = await putRequest(
    `/user/${userId}/${payload.lastname}/${payload.firstname}`,
    payload,
    true
  );
  return response;
};
