import React, { useRef, useState } from "react";
import useLangNavigate from "../hooks/useLangNavigate";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import Button from "../components/buttons/Button";
import Card from "../components/utils/Card";
import LandingStep from "../components/utils/LandingStep";
import LangButton from "../components/buttons/LangButton";
import OffoodLogo from "../components/brand/OffoodLogo";
import { usePageTitle } from "../hooks/useMeta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faApple,
  faFacebookF,
  faGooglePlay,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { faGlobe } from "@fortawesome/pro-light-svg-icons";
import { playStoreUrl, appStoreUrl } from "../utils/constants";

function Home({ tKey = "home." }) {
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  usePageTitle("home");
  const { isAuthenticated, logout } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const step1 = useRef();
  const step2 = useRef();
  const step3 = useRef();
  const step4 = useRef();
  const partners = ["ubs", "caterpillar"];

  const closeOthersSteps = (index) => {
    setCurrentStep(index);
    if (index !== 1 && step1.current) step1.current.closeStep();
    if (index !== 2 && step2.current) step2.current.closeStep();
    if (index !== 3 && step3.current) step3.current.closeStep();
    if (index !== 4 && step4.current) step4.current.closeStep();
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) section.scrollIntoView({ behavior: "smooth" });
  };

  /*
  useEffect(() => {
    openModal();
  }, [openModal]);

  const closeAndHide = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const subscribeToBeta = useCallback(async () => {
    const email =
      document.querySelector("#modal-beta input[type=email]")?.value ||
      document.querySelector("#beta-floating input")?.value;
    if (!isValidEmail(email) || !email)
      toast.error(t("betaModal.invalidEmail"));
    else {
      await subscribeToBetaRequest(email, i18n.language, userAgent)
        .then(() => {
          toast.success(t("betaModal.success"));
          closeAndHide();
          setShowFloatingBeta(false);
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [subscribeToBetaRequest, t, i18n, closeAndHide, userAgent]);
  */

  return (
    <div id="landing">
      <div id="landing-bar">
        <div id="landing-bar-content">
          <div>
            <OffoodLogo width="100px" onClick={() => navigate("/")} />
          </div>
          <div className="flex gap-1 align-center">
            <LangButton
              noFlag
              placeholder={
                <FontAwesomeIcon className="text-dark" icon={faGlobe} />
              }
            />
            {isAuthenticated ? (
              <div className="flex gap-1">
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => navigate("dashboard")}
                >
                  {t(`${tKey}dashboard`)}
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  outlined
                  onClick={() => logout()}
                >
                  {t(`dashboard.logout.sidebar`)}
                </Button>
              </div>
            ) : (
              <>
                <Button
                  variant="danger"
                  size="sm"
                  outlined
                  onClick={() => navigate("login")}
                >
                  {t(`${tKey}login`)}
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => navigate("register")}
                >
                  {t(`${tKey}register`)}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      <div id="landing-content">
        <div id="section-header" className="landing-section">
          <h1>{t(`${tKey}header.headline`)}</h1>
          <h2>{t(`${tKey}header.baseline`)}</h2>
          <div className="flex gap-1">
            <Button
              className="store-button"
              variant="primary"
              size="sm"
              onClick={() => window.open(playStoreUrl, "_blank")}
            >
              <FontAwesomeIcon icon={faGooglePlay} color="white" size="2x" />
              <div className="text-left">
                <p className="p4">{t(`${tKey}google.download`)}</p>
                <p className="p2">{t(`${tKey}google.store`)}</p>
              </div>
            </Button>
            <Button
              className="store-button"
              variant="primary"
              size="sm"
              onClick={() => window.open(appStoreUrl, "_blank")}
            >
              <FontAwesomeIcon icon={faApple} color="white" size="2x" />
              <div className="text-left">
                <p className="p4">{t(`${tKey}apple.download`)}</p>
                <p className="p2">{t(`${tKey}apple.store`)}</p>
              </div>
            </Button>
          </div>
        </div>
        <div id="landing-image-deck" className="landing-section">
          {[1, 2, 3, 4, 5].map((i) => (
            <img
              key={i}
              src={require(`../assets/img/landing/${i}.webp`)}
              alt={`Landing ${i}`}
            />
          ))}
        </div>
        <div id="section-solution" className="landing-section">
          <h1>{t(`${tKey}customers.headline`)}</h1>
          <h2>{t(`${tKey}customers.baseline`)}</h2>
          <div className="card-deck mt-3 mb-5">
            <div
              className="customer-img w-40"
              onClick={() => scrollToSection("section-customer-consumption")}
            >
              <h3 className="mb-2 text-normal">{t(`${tKey}customer.key`)}</h3>
              <span>{t(`${tKey}customer.text`)}</span>
            </div>
            <div
              className="resto-img w-40"
              onClick={() => scrollToSection("section-restaurant-values")}
            >
              <h3 className="mb-2 text-normal">{t(`${tKey}restaurant.key`)}</h3>
              <span>{t(`${tKey}restaurant.text`)}</span>
            </div>
            <div
              className="company-img w-40"
              onClick={() => scrollToSection("section-company-break")}
            >
              <h3 className="mb-2 text-normal">{t(`${tKey}company.key`)}</h3>
              <span>{t(`${tKey}company.text`)}</span>
            </div>
          </div>
        </div>
        <div id="section-customer-consumption" className="landing-section">
          <div className="landing-steps flex">
            <div className="left-side">
              <h1 className="m-0 text-normal">
                <OffoodLogo />
                <span>{t(`${tKey}step.title`)}</span>
              </h1>
              <h4 className="text-normal mt-0 mb-4">
                <span>{t(`${tKey}step.subtitle`)}</span>
                <img
                  src={require(`../assets/img/landing/iconplus.webp`)}
                  alt="Plus"
                />
              </h4>
              <LandingStep
                ref={step1}
                isOpen
                title={t(`${tKey}step1.title`)}
                text={t(`${tKey}step1.text`)}
                closeOthersSteps={() => closeOthersSteps(1)}
              />
              <LandingStep
                ref={step2}
                title={t(`${tKey}step2.title`)}
                text={t(`${tKey}step2.text`)}
                closeOthersSteps={() => closeOthersSteps(2)}
              />
              <LandingStep
                ref={step3}
                title={t(`${tKey}step3.title`)}
                text={t(`${tKey}step3.text`)}
                closeOthersSteps={() => closeOthersSteps(3)}
              />
              <LandingStep
                ref={step4}
                title={t(`${tKey}step4.title`)}
                text={t(`${tKey}step4.text`)}
                closeOthersSteps={() => closeOthersSteps(4)}
              />
            </div>
            <div className="right-side">
              <img
                src={require(`../assets/img/landing/step${currentStep}.webp`)}
                alt={`Step ${currentStep}`}
              />
            </div>
          </div>
        </div>
        <div id="section-customer-todaysspecial" className="landing-section">
          <div className="left-side">
            <img
              src={require(`../assets/img/landing/todaysspecial.webp`)}
              alt={`Step ${currentStep}`}
            />
          </div>
          <div className="right-side">
            <h1 className="mt-0 mb-4">{t(`${tKey}todaysSpecial.title`)}</h1>
            <p>{t(`${tKey}todaysSpecial.text`)}</p>
            <div className="mt-auto flex gap-1">
              <Button
                className="store-button"
                variant="primary"
                size="sm"
                onClick={() => window.open(playStoreUrl, "_blank")}
              >
                <FontAwesomeIcon icon={faGooglePlay} color="white" size="2x" />
                <div className="text-left">
                  <p className="p4">{t(`${tKey}google.download`)}</p>
                  <p className="p2">{t(`${tKey}google.store`)}</p>
                </div>
              </Button>
              <Button
                className="store-button"
                variant="primary"
                size="sm"
                onClick={() => window.open(appStoreUrl, "_blank")}
              >
                <FontAwesomeIcon icon={faApple} color="white" size="2x" />
                <div className="text-left">
                  <p className="p4">{t(`${tKey}apple.download`)}</p>
                  <p className="p2">{t(`${tKey}apple.store`)}</p>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div id="section-restaurant-values" className="landing-section">
          <h1>{t(`${tKey}restaurantValues.header`)}</h1>
          <div className="card-deck mt-3">
            <Card className="flex gap-3 w-50">
              <img
                src={require(`../assets/img/landing/restovalue1.webp`)}
                alt="Restaurant"
              />
              <div className="flex column gap-1 p-2">
                <p className="p1">
                  {t(`${tKey}restaurantValues.value1.title`)}
                </p>
                <span>{t(`${tKey}restaurantValues.value1.text`)}</span>
                <div className="mt-auto ml-auto p-3">
                  <Button
                    className="text-danger"
                    link
                    outlined
                    size="sm"
                    onClick={() => navigate("register")}
                  >
                    {t(`${tKey}restaurant.button`)}
                  </Button>
                </div>
              </div>
            </Card>
            <Card className="flex gap-3 w-50">
              <img
                src={require(`../assets/img/landing/restovalue2.webp`)}
                alt="Restaurant"
              />
              <div className="flex column gap-1 p-2">
                <p className="p1">
                  {t(`${tKey}restaurantValues.value2.title`)}
                </p>
                <span>{t(`${tKey}restaurantValues.value2.text`)}</span>
                <div className="mt-auto ml-auto p-3">
                  <Button
                    className="text-danger"
                    link
                    outlined
                    size="sm"
                    onClick={() => navigate("register")}
                  >
                    {t(`${tKey}restaurant.button`)}
                  </Button>
                </div>
              </div>
            </Card>
          </div>
        </div>
        <div id="section-restaurant-process" className="landing-section">
          <div className="landing-half">
            <img
              src={require(`../assets/img/landing/restoprocess.webp`)}
              alt="Restaurant Process"
            />
          </div>
          <div className="landing-half restaurant-process p-5">
            <div className="timeline">
              <div className="timeline-item">
                <div className="timeline-icon" />
                <div className="timeline-content">
                  <Button
                    variant="danger"
                    size="sm"
                    outlined
                    onClick={() => navigate("register")}
                  >
                    <h4>{t(`${tKey}restaurantProcess.step1.title`)}</h4>
                  </Button>
                </div>
              </div>
              <div className="timeline-item">
                <div className="timeline-icon" />
                <div className="timeline-content">
                  <h4>{t(`${tKey}restaurantProcess.step2.title`)}</h4>
                </div>
              </div>
              <div className="timeline-item">
                <div className="timeline-icon" />
                <div className="timeline-content">
                  <h4>{t(`${tKey}restaurantProcess.step3.title`)}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="section-company-break" className="landing-section">
          <div className="company-img">
            <div className="company-img-container">
              <h1 className="text-white m-0 text-normal">
                {t(`${tKey}companyBreak`)}
              </h1>
            </div>
          </div>
        </div>
        <div id="section-company-values" className="landing-section">
          <div className="left-side">
            <p>{t(`${tKey}companyValues.text`)}</p>
            <div className="mt-auto">
              <Button
                variant="danger"
                size="md"
                onClick={() => navigate("register")}
              >
                <span>{t(`${tKey}break.button2`)}</span>
              </Button>
            </div>
          </div>
          <div className="right-side">
            <img
              src={require(`../assets/img/landing/companyvalue.webp`)}
              alt={`Step ${currentStep}`}
            />
          </div>
        </div>
        <div id="section-partners" className="landing-section">
          <h3>{t(`${tKey}partners`)}</h3>
          <div className="partners">
            {partners.map((partner, i) => (
              <img
                key={i}
                src={require(`../assets/img/landing/${partner}.webp`)}
                alt={partner}
              />
            ))}
          </div>
        </div>
        <div id="footer">
          <div className="flex justify-between">
            <div className="flex gap-1">
              <Button
                className="store-button"
                variant="light"
                size="sm"
                onClick={() => window.open(playStoreUrl, "_blank")}
              >
                <FontAwesomeIcon icon={faGooglePlay} color="black" size="2x" />
                <div className="text-left">
                  <p className="p4">{t(`${tKey}google.download`)}</p>
                  <p className="p2">{t(`${tKey}google.store`)}</p>
                </div>
              </Button>
              <Button
                className="store-button"
                variant="light"
                size="sm"
                onClick={() => window.open(appStoreUrl, "_blank")}
              >
                <FontAwesomeIcon icon={faApple} color="black" size="2x" />
                <div className="text-left">
                  <p className="p4">{t(`${tKey}apple.download`)}</p>
                  <p className="p2">{t(`${tKey}apple.store`)}</p>
                </div>
              </Button>
            </div>
            <div className="flex column gap-1">
              <span>{t(`${tKey}footer.followUs`)}</span>
              <div className="flex gap-2">
                <Button
                  link
                  variant="light"
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/offood",
                      "_blank"
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    color="white"
                    size="2x"
                  />
                </Button>
                <Button
                  link
                  variant="light"
                  onClick={() =>
                    window.open("https://www.instagram.com/offood.ch", "_blank")
                  }
                >
                  <FontAwesomeIcon icon={faInstagram} color="white" size="2x" />
                </Button>
                <Button
                  link
                  variant="light"
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/share/18Dtc1TXa9/?mibextid=wwXIfr",
                      "_blank"
                    )
                  }
                >
                  <FontAwesomeIcon icon={faFacebookF} color="white" size="2x" />
                </Button>
              </div>
            </div>
            <div className="p1">{t(`${tKey}footer.baseline`)}</div>
          </div>
          <div className="flex justify-between mt-2">
            <div className="flex column gap-1">
              {isAuthenticated ? (
                <Button
                  className="text-white"
                  link
                  outlined
                  onClick={() => navigate("dashboard")}
                >
                  {t(`${tKey}dashboard`)}
                </Button>
              ) : (
                <Button
                  className="text-white"
                  link
                  outlined
                  onClick={() => navigate("login")}
                >
                  {t(`${tKey}login`)}
                </Button>
              )}
              <Button
                className="text-white"
                link
                outlined
                onClick={() => navigate("termsAndConditions")}
              >
                {t(`${tKey}footer.termsAndConditions`)}
              </Button>
              <Button
                className="text-white"
                link
                outlined
                onClick={() => navigate("consentAndCookies")}
              >
                {t(`${tKey}footer.consentAndCookies`)}
              </Button>
              <Button
                className="text-white"
                link
                outlined
                onClick={() => navigate("legalMentions")}
              >
                {t(`${tKey}footer.legalMentions`)}
              </Button>
              <Button
                className="text-white"
                link
                outlined
                onClick={() => navigate("support")}
              >
                {t(`utils.common.support`)}
              </Button>
            </div>
            <div className="flex column gap-1">
              <div className="bold">Offood SARL</div>
              <div>Chemin de la Gradelle 28</div>
              <div>1224 Chêne-Bougeries (Suisse)</div>
              <a className="text-white" href="mailto:info@offood.ch">
                info@offood.ch
              </a>
              <div>CHE-220.857.944</div>
            </div>
          </div>
          <OffoodLogo className="mt-auto" width="100%" variant="white" />
        </div>
      </div>
      {/*
      <Modal id="modal-beta" isOpen={isModalOpen} noFooter noBgClickable>
        <div className="beta-content flex column gap-1">
          <LangButton
            className="justify-end"
            noFlag
            placeholder={
              <FontAwesomeIcon className="text-dark" icon={faGlobe} />
            }
          />
          <OffoodLogo width="100%" variant="red" />
          <h2>{t("betaModal.title")}</h2>
          <p dangerouslySetInnerHTML={{ __html: t("betaModal.text") }} />
          <div className="modal-beta-input-grp flex gap-1">
            <input
              type="email"
              placeholder={t(
                `betaModal.${userAgent ? "email" : "emailNotMobile"}`,
                { agent: userAgent }
              )}
            />
            <Button
              loading={subscribeLoading}
              variant="danger"
              onClick={() => subscribeToBeta()}
            >
              {t("betaModal.submit")}
            </Button>
          </div>
          <Button className="text-medium" link onClick={() => closeAndHide()}>
            {t("betaModal.hide")}
          </Button>
        </div>
      </Modal>
      <div
        id="beta-floating"
        className={showFloatingBeta ? "show" : "hide"}
        onClick={() => setShowFloatingBeta(true)}
      >
        <div className="beta-floating-content">
          <div className="flex justify-between">
            <span>{t("betaModal.floating")}</span>
            <Button
              className="text-medium"
              link
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowFloatingBeta(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} transform={{ y: 2 }} />
            </Button>
          </div>
          <input
            type="email"
            placeholder={t(
              `betaModal.${userAgent ? "email" : "emailNotMobile"}`,
              { agent: userAgent }
            )}
          />
          <Button
            loading={subscribeLoading}
            variant="primary"
            size="sm"
            onClick={() => subscribeToBeta()}
          >
            {t("betaModal.submit")}
          </Button>
        </div>
      </div>
      */}
    </div>
  );
}

export default Home;
