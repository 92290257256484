import React, { useEffect } from "react";
import { playStoreUrl, appStoreUrl } from "../utils/constants";
import useUserAgent from "../hooks/useUserAgent";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../hooks/useLangNavigate";
import Spinner from "../components/utils/Spinner";

const StoreRedirect = () => {
  const userAgent = useUserAgent();
  const navigate = useLangNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      if (userAgent === "Apple") window.location.href = appStoreUrl;
      else if (userAgent === "Play") window.location.href = playStoreUrl;
      else navigate("/");
    }, 1500);
  }, [userAgent, navigate]);

  return (
    <div className="flex column gap-2 items-center justify-center h-100">
      <Spinner variant="danger" size="5x" />
      <h2 className="text-center">{t("utils.redirectStore")}</h2>
    </div>
  );
};

export default StoreRedirect;
