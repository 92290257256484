import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import DropdownIndicator from "./DropdownIndicator";
import { useTranslation } from "react-i18next";

const TimeRangeInput = ({
  value = { startAt: null, endAt: null },
  step,
  onChange,
  maxHour = 24,
  minHour = 0,
  isStartClearable = false,
  isEndClearable = false,
  classNameInput = "",
}) => {
  const { t } = useTranslation();

  const times = useMemo(() => {
    const t = [];
    const startRange = minHour * 60;
    const endRange = (maxHour + 0.25) * 60;
    for (let i = startRange; i < endRange; i += step) {
      const hh = Math.floor(i / 60);
      const mm = i % 60;
      t.push(
        `${hh.toString().padStart(2, "0")}:${mm.toString().padStart(2, "0")}`
      );
    }
    return t;
  }, [step, minHour, maxHour]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [startOptions, setStartOptions] = useState([]);
  const [endOptions, setEndOptions] = useState([]);

  const handleStartTimeChange = (time) => {
    const timeValue = time?.value;
    setStartTime(time);
    if (times.indexOf(timeValue) >= times.indexOf(endTime?.value)) {
      setEndTime(null);
      onChange({ startAt: timeValue, endAt: null });
    } else {
      onChange({ startAt: timeValue, endAt: endTime?.value });
    }
  };

  const handleEndTimeChange = (time) => {
    const timeValue = time?.value;
    setEndTime(time);
    if (times.indexOf(startTime?.value) >= times.indexOf(timeValue)) {
      setStartTime(null);
      onChange({ startAt: null, endAt: timeValue });
    } else {
      onChange({ startAt: startTime?.value, endAt: timeValue });
    }
  };

  useEffect(() => {
    setStartTime(
      value.startAt ? { label: value.startAt, value: value.startAt } : null
    );
    setEndTime(value.endAt ? { label: value.endAt, value: value.endAt } : null);
  }, [value]);

  useEffect(() => {
    const startIndex = times.indexOf(startTime?.value);
    const endIndex = times.indexOf(endTime?.value);
    setStartOptions(
      times.slice(0, endIndex).map((time) => ({ label: time, value: time }))
    );
    setEndOptions(
      times.slice(startIndex + 1).map((time) => ({ label: time, value: time }))
    );
  }, [times, startTime, endTime]);

  return step ? (
    <div className="flex gap-1">
      <Select
        components={{ DropdownIndicator }}
        className={`react-custom-select flex-grow ${classNameInput}`}
        classNamePrefix="react-select"
        placeholder={t("utils.common.start")}
        options={startOptions}
        value={startTime}
        onChange={handleStartTimeChange}
        isClearable={isStartClearable}
      />
      <Select
        components={{ DropdownIndicator }}
        className={`react-custom-select flex-grow ${classNameInput}`}
        classNamePrefix="react-select"
        placeholder={t("utils.common.end")}
        options={endOptions}
        value={endTime}
        onChange={handleEndTimeChange}
        isClearable={isEndClearable}
      />
    </div>
  ) : (
    <div>
      <input
        type="time"
        value={startTime}
        placeholder={t("utils.common.start")}
        onChange={handleStartTimeChange}
      />
      <input
        type="time"
        value={endTime}
        placeholder={t("utils.common.end")}
        onChange={handleEndTimeChange}
      />
    </div>
  );
};

export default TimeRangeInput;
