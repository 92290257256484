import { useState, useEffect } from "react";

function useUserAgent() {
  const [userAgent, setUserAgent] = useState(null);

  useEffect(() => {
    const _userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(_userAgent)) setUserAgent("Android");
    else if (/iPad|iPhone|iPod/.test(_userAgent) && !window.MSStream)
      setUserAgent("Apple");
    else if (/Win|Mac|Linux|X11/.test(_userAgent)) setUserAgent("Computer");
    else setUserAgent("Unknown");
  }, []);

  return userAgent;
}

export default useUserAgent;
