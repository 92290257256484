import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SidebarItem from "./SidebarItem";
import LangButton from "../buttons/LangButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faBell, faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import Button from "../buttons/Button";
import NotificationsList from "../notifications/NotificationsList";
import RestaurantAppPreview from "../../components/app/RestaurantAppPreview";
import { useAuth } from "../../hooks/useAuth";
import useLangNavigate from "../../hooks/useLangNavigate";
import useWebSocket from "../../hooks/useWebSocket";
import useApi from "../../hooks/useApi";
import {
  getRestaurant,
  putSwitchRestaurant,
  putSwitchCatering,
} from "../../services/restaurantService";
import { useSelector, useDispatch } from "react-redux";
import {
  stopControllingRestaurant,
  stopControllingCompany,
} from "../../redux/slices/adminSlice";
import { setRestaurantId } from "../../redux/slices/userSlice";
import UserAvatar from "../utils/UserAvatar";

const MobileSidebar = ({
  menuList,
  onClose,
  isClickable,
  className,
  isStripeDone = null,
  stripeUrl = null,
}) => {
  const { logout, isAuthenticated, isRestaurantAdmin, isOffoodAdmin } =
    useAuth();
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const [showNotifications, setShowNotifications] = useState(false);
  const [showRestaurantMenu, setShowRestaurantMenu] = useState(false);
  const [isOnOverview, setIsOnOverview] = useState(false);
  const [isRestaurantActive, setIsRestaurantActive] = useState(false);
  const [isCateringActive, setIsCateringActive] = useState(false);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const restaurants = useSelector((state) => state.user.restaurants);
  const {
    controlledRestaurantId,
    controlledCompanyId,
    isControllingEntity,
    controlledConsumptionModes,
  } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const { request: fetchRestaurant } = useApi(getRestaurant);
  const user = useSelector((state) => state.user);
  const { notifications, setNotifications } = useWebSocket(
    "notifications",
    user.userId
  );
  const { request: switchRestaurant } = useApi(putSwitchRestaurant);
  const { request: switchCatering } = useApi(putSwitchCatering);

  useEffect(() => {
    setIsOnOverview(window.location.pathname.endsWith("/dashboard"));
  }, []);

  useEffect(() => {
    if (isOnOverview && isRestaurantAdmin && !isOffoodAdmin && restaurantId) {
      fetchRestaurant(restaurantId).then((response) => {
        const restaurant = response.data;
        setIsRestaurantActive(restaurant.isActive);
        setIsCateringActive(restaurant.isCateringActive);
      });
    }
  }, [
    isRestaurantAdmin,
    isOnOverview,
    isRestaurantActive,
    isCateringActive,
    isOffoodAdmin,
    restaurantId,
    fetchRestaurant,
  ]);

  const handleSwitchRestaurant = () => {
    switchRestaurant(restaurantId)
      .then((response) => {
        setIsRestaurantActive(response.data.isActive);
        toast.success(
          t(
            `dashboard.restaurant.toasts.switchRestaurant${
              response.data.isActive ? "Active" : "Inactive"
            }`
          )
        );
      })
      .catch((error) => {
        toast.error(error.message);
        console.error(error);
      });
  };

  const handleSwitchCatering = () => {
    switchCatering(restaurantId)
      .then((response) => {
        setIsCateringActive(response.data.isCateringActive);
        toast.success(
          t(
            `dashboard.restaurant.toasts.switchCatering${
              response.data.isCateringActive ? "Active" : "Inactive"
            }`
          )
        );
      })
      .catch((error) => {
        toast.error(error.message);
        console.error(error);
      });
  };

  const handleStopControllingRestaurant = () => {
    dispatch(stopControllingRestaurant());
    navigate("dashboard");
  };

  const handleStopControllingCompany = () => {
    dispatch(stopControllingCompany());
    navigate("dashboard");
  };

  const handleShowNotifications = () => {
    setShowNotifications(!showNotifications);
    if (showNotifications) setNotifications(0);
  };

  const handleSelectRestaurant = (restaurantId) => {
    dispatch(setRestaurantId(restaurantId));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const showCateringButton = useCallback(() => {
    if (controlledRestaurantId) {
      controlledConsumptionModes?.find((mode) => mode.consumptionModeId === 5);
    } else {
      const restaurant = restaurants?.find(
        (r) => r.restaurantId === restaurantId
      );
      return restaurant?.consumptionmodes?.find(
        (mode) => mode.consumptionModeId === 5
      );
    }
  }, [
    restaurantId,
    restaurants,
    controlledRestaurantId,
    controlledConsumptionModes,
  ]);

  return (
    <div className={`mobile-sidebar-overlay ${className || ""}`}>
      {showNotifications && (
        <div id="notifications">
          <NotificationsList
            userId={user.userId}
            closeNotifications={() => setShowNotifications(false)}
          />
        </div>
      )}
      {showRestaurantMenu && (
        <div id="restaurantmenu" className="flex column gap-1">
          <p className="bold">
            {restaurants.find((r) => r.restaurantId === restaurantId).name}
          </p>
          {restaurants
            .filter((r) => r.restaurantId !== restaurantId)
            .map((restaurant) => (
              <Button
                variant="tertiary"
                className="p-4 justify-start gap-2"
                block
                onClick={() => handleSelectRestaurant(restaurant.restaurantId)}
                key={restaurant.restaurantId}
              >
                <RestaurantAppPreview
                  size="45"
                  img={restaurant.images?.[0]?.fullImagePath}
                  restaurantType={restaurant.restauranttype}
                />
                <span>{restaurant.name}</span>
              </Button>
            ))}
          <Button
            size="sm"
            block
            onClick={() => navigate("register", { state: { initialStep: 2 } })}
          >
            <span>{t("dashboard.restaurant.addRestaurant")}</span>
          </Button>
        </div>
      )}
      <div className="mobile-sidebar" onClick={(e) => e.stopPropagation()}>
        <div className="mobile-sidebar-header">
          <button onClick={onClose} className="close-btn">
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <div className="flex gap-1 align-center flex-wrap">
            {isOnOverview &&
            (isRestaurantAdmin ||
              (isOffoodAdmin && !!controlledRestaurantId)) &&
            isStripeDone ? (
              <>
                <Button
                  size="sm"
                  disabled={!isStripeDone}
                  variant={isRestaurantActive ? "danger" : "success"}
                  onClick={() => handleSwitchRestaurant()}
                >
                  {t(
                    `dashboard.restaurant.switchRestaurant${
                      isRestaurantActive ? "Inactive" : "Active"
                    }`
                  )}
                </Button>
                {showCateringButton() && (
                  <Button
                    size="sm"
                    disabled={!isStripeDone}
                    variant={isCateringActive ? "danger" : "success"}
                    onClick={() => handleSwitchCatering()}
                  >
                    {t(
                      `dashboard.restaurant.switchCatering${
                        isCateringActive ? "Inactive" : "Active"
                      }`
                    )}
                  </Button>
                )}
              </>
            ) : (
              <Button
                size="sm"
                variant="danger"
                onClick={() => window.open(stripeUrl, "_blank")}
              >
                {t(`dashboard.restaurant.stripeNotConnectedShort`)}
              </Button>
            )}
            <UserAvatar src={user.imagePath} size="40" />
            {isRestaurantAdmin && restaurantId && !controlledRestaurantId && (
              <div
                className="p-1 flex gap-1 align-center bg-pastel-light very-rounded cursor-pointer"
                onClick={() => setShowRestaurantMenu(!showRestaurantMenu)}
              >
                <RestaurantAppPreview
                  isAvatar
                  size="40"
                  img={
                    restaurants.find((r) => r.restaurantId === restaurantId)
                      .images?.[0]?.fullImagePath
                  }
                  restaurantType={
                    restaurants.find((r) => r.restaurantId === restaurantId)
                      .restauranttype
                  }
                />
                <span>
                  {restaurants.find((r) => r.restaurantId === restaurantId).name
                    .length > 12
                    ? `${restaurants
                        .find((r) => r.restaurantId === restaurantId)
                        .name.slice(0, 9)}...`
                    : restaurants.find((r) => r.restaurantId === restaurantId)
                        .name}
                </span>
              </div>
            )}
            <Button
              variant="light"
              size="sm"
              rounded
              onClick={handleShowNotifications}
            >
              <span className="fa-layers fa-fw">
                <FontAwesomeIcon color="black" size="xl" icon={faBell} />
                {notifications > 0 && (
                  <FontAwesomeIcon
                    icon={faCircle}
                    size="xs"
                    color="red"
                    transform="right-10 up-10"
                  />
                )}
              </span>
            </Button>
          </div>
        </div>
        <div className="mobile-sidebar-list">
          {menuList.map((item, index) => (
            <SidebarItem
              className="mobile-sidebar-item"
              key={index}
              item={item}
              isClickable={isClickable}
            />
          ))}
        </div>
        <div className="mobile-sidebar-footer">
          {controlledRestaurantId && isOffoodAdmin && (
            <Button variant="primary" onClick={handleStopControllingRestaurant}>
              {t("dashboard.admin.clearControlledRestaurant")}
            </Button>
          )}
          {controlledCompanyId && isOffoodAdmin && (
            <Button variant="primary" onClick={handleStopControllingCompany}>
              {t("dashboard.admin.clearControlledCompany")}
            </Button>
          )}
          {!isControllingEntity && (
            <Button
              className="gap-1"
              variant="info"
              size="sm"
              onClick={() => navigate("support")}
            >
              <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
              <span>{t("utils.common.support")}</span>
            </Button>
          )}
          <LangButton block noFlag={false} />
          {isAuthenticated && (
            <SidebarItem
              className="mobile-sidebar-item"
              item={{ tKey: "dashboard.logout.", onClick: logout }}
              isClickable={isClickable}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;
