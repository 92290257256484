import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Button from "../components/buttons/Button";
import LangButton from "../components/buttons/LangButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../hooks/useLangNavigate";
import useBreakpoint from "../hooks/useBreakpoint";
import OffoodLogo from "../components/brand/OffoodLogo";
import Collapse from "../components/utils/Collapse";
import Form from "../components/inputs/Form";

const Support = ({ tKey = "support." }) => {
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const [data, setData] = useState({
    userId: null,
    firstname: "",
    lastname: "",
    category: "",
    email: "",
    message: "",
  });
  const user = useSelector((state) => state.user);
  const { isLtLaptop } = useBreakpoint();

  const categories = useMemo(() => {
    return [
      { value: "general", label: t(`${tKey}categories.general`) },
      { value: "suggestion", label: t(`${tKey}categories.suggestion`) },
      { value: "technical", label: t(`${tKey}categories.technical`) },
      { value: "account", label: t(`${tKey}categories.account`) },
      { value: "other", label: t(`${tKey}categories.other`) },
    ];
  }, [t, tKey]);

  const fields = useMemo(() => {
    return [
      {
        label: t(`${tKey}fields.firstname`),
        name: "firstname",
        type: "text",
        class: "col-6 pr-2",
        required: true,
        placeholder: t(`${tKey}fields.firstname`),
      },
      {
        label: t(`${tKey}fields.lastname`),
        name: "lastname",
        type: "text",
        class: "col-6 pl-2",
        required: true,
        placeholder: t(`${tKey}fields.lastname`),
      },
      {
        label: t(`${tKey}fields.email`),
        name: "email",
        type: "email",
        class: "col-12",
        required: true,
        placeholder: t(`${tKey}fields.email`),
      },
      {
        label: t(`${tKey}fields.category`),
        name: "category",
        type: "dropdown",
        class: "col-12",
        required: true,
        options: categories,
      },
      {
        label: t(`${tKey}fields.message`),
        name: "message",
        type: "textarea",
        class: "col-12",
        rows: 5,
        required: true,
        placeholder: t(`${tKey}fields.message`),
      },
    ];
  }, [t, tKey, categories]);

  useEffect(() => {
    if (user) {
      setData((prevData) => ({
        ...prevData,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
      }));
    }
  }, [user]);

  const handleSubmit = useCallback(() => {
    const subject = `[${data.category.label} Issue] ${
      !!user ? `#${user.userId} ` : ""
    }${data.firstname} ${data.lastname} (${data.email})`;
    const mailto = `mailto:info@offood.ch?subject=${subject}&body=${encodeURIComponent(
      data.message
    )}`;
    window.location.href = mailto;
  }, [data, user]);

  const helpHeader = () => {
    return (
      <div className="flex justify-between">
        <OffoodLogo height="45px" variant="red" onClick={() => navigate("/")} />
        <Button variant="light" onClick={() => window.history.back()}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </Button>
      </div>
    );
  };

  return (
    <div id="support">
      <div className="img-container">
        {!isLtLaptop() ? helpHeader() : null}
        <div className="flex column gap-1">
          <h3 className="text-white">{t(`${tKey}faq.header`)}</h3>
          {Array.from({ length: 3 }).map((_, i) => (
            <Collapse
              className="bg-white rounded"
              title={t(`${tKey}faq.q${i}.title`)}
            >
              <div className="p-4">{t(`${tKey}faq.q${i}.content`)}</div>
            </Collapse>
          ))}
        </div>
      </div>
      <div className="container">
        {isLtLaptop() ? helpHeader() : null}
        <h3 className="flex justify-between">
          <span>{t(`${tKey}title`)}</span>
          <LangButton />
        </h3>
        <Form
          fields={fields}
          currentForm={data}
          setFormDetails={setData}
          setIsValid={setIsValid}
        />
        <Button variant="primary" disabled={!isValid} onClick={handleSubmit}>
          {t(`${tKey}submit`)}
        </Button>
      </div>
    </div>
  );
};

export default Support;
