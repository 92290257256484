import { useEffect, useCallback, useState } from "react";
import { getRestaurantStripeStatus } from "../services/restaurantService";
import useApi from "./useApi";
import { useTranslation } from "react-i18next";

export function useStripeDone(restaurantId) {
  const [isStripeDone, setIsStripeDone] = useState(null);
  const [stripeUrl, setStripeUrl] = useState(null);
  const { request: getStripeStatusRequest } = useApi(getRestaurantStripeStatus);

  const lang = useTranslation().i18n.language;

  const fetchRestaurantStripeDone = useCallback(async () => {
    if (!restaurantId) return;
    await getStripeStatusRequest(restaurantId, lang)
      .then((response) => {
        setIsStripeDone(response.data.stripeDone);
        setStripeUrl(response.data.stripeUrl);
      })
      .catch((error) => {
        setIsStripeDone(false);
        setStripeUrl(null);
        console.log(error);
      });
  }, [
    getStripeStatusRequest,
    setIsStripeDone,
    setStripeUrl,
    restaurantId,
    lang,
  ]);

  useEffect(() => {
    if (isStripeDone === null) fetchRestaurantStripeDone();
  }, [fetchRestaurantStripeDone, isStripeDone, lang]);

  return { isStripeDone, stripeUrl };
}
