import React from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import { SettingsProvider } from "../../contexts/SettingsContext";
import { NavigationProvider } from "../../contexts/NavigationContext";

const DashboardLayout = ({
  menuList,
  navigatePath,
  stripeDetails,
  children,
}) => {
  return (
    <SettingsProvider>
      <NavigationProvider>
        <div className="sidebar-page-wrapper">
          <Sidebar menuList={menuList} isClickable stripe={stripeDetails} />
          <div id="dashboard">
            <Navbar
              navigateBack={navigatePath}
              isStripeDone={stripeDetails?.isStripeDone}
              stripeUrl={stripeDetails?.stripeUrl}
            />
            <>{children}</>
          </div>
        </div>
      </NavigationProvider>
    </SettingsProvider>
  );
};

export default DashboardLayout;
