import React, { useEffect, useState, useMemo, useCallback } from "react";
import { usePageTitle } from "../../../hooks/useMeta";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import useApi from "../../../hooks/useApi";
import {
  getAllRestaurants,
  switchOnboardingDone,
} from "../../../services/admin/restaurantService";
import Table from "../../table/Table";
import NoData from "../../errors/NoData";
import DecoratedInput from "../../inputs/DecoratedInput";
import DropdownButton from "../../buttons/DropdownButton";
import { startControllingRestaurant } from "../../../redux/slices/adminSlice";
import useLangNavigate from "../../../hooks/useLangNavigate";
import Spinner from "../../utils/Spinner";

export const AdminRestaurants = ({ tKey = "dashboard.admin.restaurant." }) => {
  usePageTitle("adminRestaurants");
  const { t } = useTranslation();
  const [restaurants, setRestaurants] = useState([]);
  const [filtered, setFilteredRestaurants] = useState([]);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useLangNavigate();
  const { request: getRestaurants, loading } = useApi(getAllRestaurants);
  const { request: switchOnboarding } = useApi(switchOnboardingDone);

  const handleRestaurantControl = useCallback(
    (row) => {
      dispatch(
        startControllingRestaurant({
          ...row,
          imagePath: row.images?.find((i) => i.isMainImage)?.fullImagePath,
        })
      );
      navigate("dashboard");
    },
    [dispatch, navigate]
  );

  const handleRestaurantDetail = useCallback(
    (row) => {
      navigate(`dashboard/admin-restaurants/${row.original.restaurantId}`);
    },
    [navigate]
  );

  const handleSwitchOnboarding = useCallback(
    async (event, restaurant) => {
      event.stopPropagation();
      switchOnboarding(restaurant.restaurantId)
        .then(() => {
          const newRestaurants = restaurants.map((r) => {
            if (r.restaurantId === restaurant.restaurantId) {
              r.onboardingDone = !r.onboardingDone;
            }
            return r;
          });
          setRestaurants(newRestaurants);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [restaurants, switchOnboarding]
  );

  const columns = useMemo(
    () => [
      {
        accessor: "restaurantId",
        Cell: "",
      },
      {
        Header: t(`${tKey}image`),
        Cell: ({ row }) =>
          row.original.images.find((i) => i.isMainImage) ? (
            <img
              className="list-preview-img"
              src={row.original.images.find((i) => i.isMainImage).fullImagePath}
              alt={row.original.name}
              height={50}
            />
          ) : (
            "No image"
          ),
        disableSortBy: true,
      },
      {
        Header: t(`${tKey}name`),
        accessor: "name",
      },
      {
        Header: t(`${tKey}address`),
        accessor: "address",
      },
      {
        Header: t(`${tKey}city`),
        accessor: (row) => (
          <>
            <div>{`${row.city.npa} ${row.city.label}`}</div>
            <div className="text-light">{`${row.region.label}`}</div>
          </>
        ),
      },
      {
        Header: t(`${tKey}onboardingDone`),
        accessor: "onboardingDone",
        Cell: ({ row }) => (
          <div
            onClick={(event) => handleSwitchOnboarding(event, row.original)}
            className={`rounded text-white py-1 px-3 text-center fit-content ${
              row.original?.onboardingDone ? "bg-success" : "bg-danger"
            }`}
          >
            {t(`utils.common.${row.original?.onboardingDone ? "yes" : "no"}`)}
          </div>
        ),
      },
      {
        Header: "",
        id: "actions",
        Cell: ({ row }) => (
          <DropdownButton
            actions={[
              {
                label: t(`${tKey}actions.edit`),
                onClick: () => handleRestaurantDetail(row.original),
              },
              {
                label: t(`${tKey}actions.takeControl`),
                onClick: () => handleRestaurantControl(row.original),
              },
            ]}
          >
            <FontAwesomeIcon color="grey" icon={faEllipsisVertical} />
          </DropdownButton>
        ),
        disableSortBy: true,
      },
    ],
    [
      handleRestaurantControl,
      handleRestaurantDetail,
      handleSwitchOnboarding,
      t,
      tKey,
    ]
  );

  useEffect(() => {
    const fetchRestaurants = async () => {
      await getRestaurants()
        .then((response) => {
          setRestaurants(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchRestaurants();
  }, [getRestaurants]);

  useEffect(() => {
    if (query === null || query === "") setFilteredRestaurants(restaurants);
    else
      setFilteredRestaurants(
        restaurants.filter((restaurant) =>
          restaurant.name.toLowerCase().includes(query.toLowerCase())
        )
      );
  }, [restaurants, query]);

  if (loading) return <Spinner />;

  return (
    <div className="dashboard-content">
      <DecoratedInput
        className="bg-white pl-2 dashboard-search-bar w-100"
        input={{
          id: "searchRestaurant",
          class: "p4",
          value: "",
          placeholder: t(`${tKey}searchPlaceholder`, {
            count: restaurants ? restaurants.length : 0,
          }),
        }}
        hasResearchButton
        onResearchButtonClick={(query) => {
          setQuery(query);
        }}
      />
      <div className="table-container mt-4">
        {filtered?.length === 0 ? (
          <NoData />
        ) : (
          <Table
            rowClassName="p-4"
            columns={columns}
            data={filtered}
            onClickRow={handleRestaurantDetail}
          />
        )}
      </div>
    </div>
  );
};

export default AdminRestaurants;
