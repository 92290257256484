import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from "../api/methodsCfg";

export const getDish = async (dishId) => {
  const response = await getRequest(`/dish/${dishId}`);
  return response;
};

export const getDishes = async (restaurantId) => {
  const response = await getRequest(`/dish/byRestaurant/${restaurantId}`);
  return response;
};

export const getCategories = async (restaurantId) => {
  const response = await getRequest(`/dishcategory/restaurant/${restaurantId}`);
  return response;
};

export const getDishesByType = async (restaurantId, typeId) => {
  const response = await getRequest(`/dish/byType/${restaurantId}`, { typeId });
  return response;
};

export const getRelatedMenusAndTodaysSpecials = async (dishId) => {
  const response = await getRequest(`/dish/relatedItems/${dishId}`);
  return response;
};

export const deleteDish = async (dishId) => {
  const response = await deleteRequest(`/dish/${dishId}`);
  return response;
};

export const updateDishesOrder = async (restaurantId, ids) => {
  const response = await putRequest(`/dish/order/${restaurantId}`, { ids });
  return response;
};

export const creatNewDish = async (payload) => {
  const cleanPayload = {
    imagePath: payload.imagePath,
    name: payload.name,
    description: payload.description,
    isVegetarian: payload.isVegetarian || false,
    isVegan: payload.isVegan || false,
    isHomemade: payload.isHomemade || false,
    isActive: payload.isActive || false,
    isPublished: payload.isPublished || false,
    price: payload.price,
    discount: payload.discount,
    categoryId: payload.dishcategory?.value?.dishCategoryId,
    typeId: payload.dishtype.value.dishTypeId,
    allergiesIds: payload.allergies
      ? payload.allergies.map((a) => a.value?.allergyId || a.allergyId)
      : [],
    restaurantId: payload.restaurantId,
  };
  const response = await postRequest(
    `/dish/${payload.name}/${payload.restaurantId}`,
    cleanPayload,
    true
  );
  return response;
};

export const duplicateDish = async (payload) => {
  delete payload.dishId;
  payload.isActive = false;
  payload.isPublished = false;
  const response = await postRequest(
    `/dish/${payload.name}/${payload.restaurantId}`,
    payload
  );
  return response;
};

export const updateDish = async (payload) => {
  const cleanPayload = {
    imagePath: payload.imagePath,
    name: payload.name,
    description: payload.description,
    isVegetarian: payload.isVegetarian || false,
    isVegan: payload.isVegan || false,
    isHomemade: payload.isHomemade || false,
    isActive: payload.isActive || false,
    isPublished: payload.isPublished || false,
    price: payload.price,
    discount: payload.discount,
    categoryId: payload.dishcategory?.value?.dishCategoryId,
    typeId: payload.dishtype.value.dishTypeId,
    allergiesIds: payload.allergies.map(
      (a) => a.value?.allergyId || a.allergyId
    ),
    order: payload.order,
  };
  const response = await putRequest(
    `/dish/${payload.dishId}/${payload.name}/${payload.restaurantId}`,
    cleanPayload,
    true
  );
  return response;
};

export const switchActivateDish = async (dishId) => {
  const response = await putRequest(`/dish/active/${dishId}`);
  return response;
};

export const updateCategoriesOrder = async (restaurantId, ids) => {
  const response = await putRequest(`/dishcategory/order/${restaurantId}`, {
    ids,
  });
  return response;
};
