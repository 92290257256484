import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { usePageTitle } from "../../../hooks/useMeta";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useApi from "../../../hooks/useApi";
import Tabs from "../../tabs/Tabs";
import UserAdmin from "../settings/admin/UserAdmin";
import Connection from "../settings/Connection";
import { updateUser } from "../../../redux/slices/userSlice";
import { putAttemptChangePassword } from "../../../services/authService";
import {
  getUserDetails,
  updateUserDetails,
} from "../../../services/admin/userService";
import SettingsContext from "../../../contexts/SettingsContext";
import useBreakpoint from "../../../hooks/useBreakpoint";
import Button from "../../buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-light-svg-icons";
import Spinner from "../../utils/Spinner";

export const AdminSettings = ({ tKey = "dashboard.settings." }) => {
  usePageTitle("adminSettings");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [userAdmin, setUserAdmin] = useState({});
  const [tempUserAdminSettings, setTempUserAdminSettings] = useState({});
  const [tempPassword, setTempPassword] = useState({});
  const { request: getUserAdminRequest, loading: userAdminLoading } =
    useApi(getUserDetails);
  const { request: updateUserAdminRequest, loading: updateUserAdminLoading } =
    useApi(updateUserDetails);
  const {
    request: attemptChangePasswordRequest,
    loading: updatePasswordLoading,
  } = useApi(putAttemptChangePassword);
  const user = useSelector((state) => state.user);
  const {
    activeTab,
    setActiveTab,
    isValid,
    setIsValid,
    setApplyChanges,
    setHideSettingsButton,
  } = useContext(SettingsContext);
  const { isLtLaptop } = useBreakpoint();

  const handleUserAdminDataChange = useCallback(
    (data) => {
      setIsValid(!!data);
      setTempUserAdminSettings((old) => {
        return { ...old, ...data };
      });
    },
    [setIsValid, setTempUserAdminSettings]
  );

  const handlePasswordChange = useCallback(
    (data) => {
      setIsValid(!!data);
      setTempPassword(data);
    },
    [setIsValid, setTempPassword]
  );

  const tabs = useMemo(
    () => [
      {
        name: "userAdmin",
        content: () => (
          <UserAdmin
            data={userAdmin}
            onFormDataChange={handleUserAdminDataChange}
          />
        ),
      },
      {
        name: "connection",
        content: () => <Connection onFormDataChange={handlePasswordChange} />,
      },
    ],
    [handleUserAdminDataChange, handlePasswordChange, userAdmin]
  );

  const fetchSettingsUserAdmin = useCallback(
    async (userId) => {
      await getUserAdminRequest(userId)
        .then((response) => {
          setUserAdmin(response.data);
          dispatch(updateUser(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [getUserAdminRequest, dispatch]
  );

  const attemptUpdateUserAdmin = useCallback(async () => {
    await updateUserAdminRequest(userAdmin.userId, tempUserAdminSettings).then(
      async () => {
        toast.success(t(`${tKey}success`));
        await fetchSettingsUserAdmin(userAdmin.userId);
      }
    );
  }, [
    updateUserAdminRequest,
    userAdmin.userId,
    tempUserAdminSettings,
    t,
    tKey,
    fetchSettingsUserAdmin,
  ]);

  const attemptChangePassword = useCallback(async () => {
    await attemptChangePasswordRequest(user.userId, tempPassword).then(() => {
      toast.success(t(`${tKey}success`));
    });
  }, [attemptChangePasswordRequest, user.userId, tempPassword, t, tKey]);

  const applyChanges = useCallback(() => {
    switch (activeTab) {
      case "userAdmin":
        return userAdmin ? attemptUpdateUserAdmin() : null;
      case "connection":
        return attemptChangePassword();
      default:
        return userAdmin ? attemptUpdateUserAdmin() : null;
    }
  }, [activeTab, userAdmin, attemptUpdateUserAdmin, attemptChangePassword]);

  const setActiveTabWrapper = useCallback(
    (tab) => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );

  useEffect(() => {
    setActiveTabWrapper("userAdmin");
    setHideSettingsButton(false);
    fetchSettingsUserAdmin(user.userId);
    return () => {
      setHideSettingsButton(true);
    };
  }, [
    setHideSettingsButton,
    setActiveTabWrapper,
    fetchSettingsUserAdmin,
    user.userId,
  ]);

  useEffect(() => {
    setApplyChanges(() => applyChanges);
  }, [applyChanges, setApplyChanges]);

  useEffect(() => {
    if (updateUserAdminLoading || updatePasswordLoading) setIsValid(false);
  }, [updateUserAdminLoading, updatePasswordLoading, setIsValid]);

  if (userAdminLoading) return <Spinner />;

  return (
    <div id="settings" className="dashboard-content">
      <Tabs
        preventSwitchTab={false}
        tKey={tKey}
        tKeySuffix=".tab"
        tabs={tabs}
        onTabChange={setActiveTabWrapper}
        contentClass="flex gap-1 settings-content"
      ></Tabs>
      {isLtLaptop() && (
        <Button
          onClick={applyChanges}
          floating
          variant="success"
          disabled={!isValid}
          className="flex gap-1"
        >
          <FontAwesomeIcon size="2x" icon={faSave} />
          <span>{t(`utils.common.save`)}</span>
        </Button>
      )}
    </div>
  );
};

export default AdminSettings;
