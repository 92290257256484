import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import Select from "react-select";
import useApi from "../../../../hooks/useApi";
import {
  getCompanyUsers,
  closeCompanyManagerAccount,
} from "../../../../services/userService";
import { deleteCompany } from "../../../../services/companyService";
import { useAuth } from "../../../../hooks/useAuth";
import Alert from "../../../utils/Alert";
import Button from "../../../buttons/Button";
import DropdownIndicator from "../../../inputs/DropdownIndicator";

const DangerZone = ({ tKey = "dashboard.settings.dangerZone." }) => {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [hasToSelectNewManager, setHasToSelectNewManager] = useState(false);
  const [hasToConfirmCloseAccount, setHasToConfirmCloseAccount] =
    useState(false);
  const { logout } = useAuth();
  const { request: getUsersRequest } = useApi(getCompanyUsers);
  const { request: deleteCompanyRequest } = useApi(deleteCompany);
  const { request: closeCompanyManagerAccountRequest } = useApi(
    closeCompanyManagerAccount
  );
  const user = useSelector((state) => state.user);
  const controlledCompanyId = useSelector(
    (state) => state.admin.controlledCompanyId
  );

  useEffect(() => {
    getUsersRequest(user.companyId)
      .then((response) => {
        setEmployees(
          response.data.users.filter(
            (employee) =>
              employee.userId !== user.userId && employee.isValidated
          )
        );
      })
      .catch((error) => {
        console.log(error);
        setEmployees([]);
      });
  }, [user.companyId, user.userId, getUsersRequest]);

  const attemptCloseCompany = useCallback(
    async (companyId) => {
      await deleteCompanyRequest(companyId)
        .then(() => {
          logout();
        })
        .catch((error) => {
          toast(t(`${tKey}companyDeletion.error`), "error");
          console.log(error);
        });
    },
    [logout, t, tKey, deleteCompanyRequest]
  );

  const handleCloseCompany = useCallback(() => {
    if (!selectedEmployee) return;
    attemptCloseCompany(controlledCompanyId || user.restaurantId);
  }, [
    selectedEmployee,
    controlledCompanyId,
    user.restaurantId,
    attemptCloseCompany,
  ]);

  const attemptCloseAccount = useCallback(async () => {
    if (!selectedEmployee) return;
    await closeCompanyManagerAccountRequest(
      controlledCompanyId || user.companyId,
      {
        userId: selectedEmployee.userId,
        oldManagerId: controlledCompanyId ? null : user.userId,
      }
    )
      .then(() => {
        logout();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  }, [
    logout,
    controlledCompanyId,
    closeCompanyManagerAccountRequest,
    user.companyId,
    user.userId,
    selectedEmployee,
  ]);

  return (
    <>
      <div className="dashboard-content flex gap-2">
        <div>
          <Alert variant="danger">
            <h4 className="my-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
              {t(`${tKey}accountDeletion.alert.title`)}
            </h4>
            <p>{t(`${tKey}accountDeletion.alert.text`)}</p>
            <Button
              variant={hasToConfirmCloseAccount ? "secondary" : "danger"}
              size="sm"
              className="mt-2"
              onClick={() =>
                setHasToConfirmCloseAccount(!hasToConfirmCloseAccount)
              }
            >
              {t(
                hasToConfirmCloseAccount
                  ? `utils.common.cancel`
                  : `${tKey}accountDeletion.button`
              )}
            </Button>
            {hasToConfirmCloseAccount && (
              <Button
                variant="danger"
                size="sm"
                className="mt-2"
                onClick={handleCloseCompany}
              >
                {t(`${tKey}accountDeletion.confirmButton`)}
              </Button>
            )}
          </Alert>
        </div>
        <div>
          <Alert variant="danger">
            <h4 className="my-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
              {t(`${tKey}companyDeletion.alert.title`)}
            </h4>
            <p>{t(`${tKey}companyDeletion.alert.text`)}</p>
            <div className="flex align-center gap-1">
              <Button
                variant={hasToSelectNewManager ? "secondary" : "danger"}
                size="sm"
                className="mt-2"
                onClick={() => setHasToSelectNewManager(!hasToSelectNewManager)}
              >
                {t(
                  hasToSelectNewManager
                    ? `utils.common.cancel`
                    : `${tKey}companyDeletion.button`
                )}
              </Button>
              {hasToSelectNewManager && (
                <Select
                  className="react-custom-select text-dark col-3"
                  classNamePrefix="react-select"
                  options={employees.map((employee) => ({
                    value: employee,
                    label: `${employee.firstname} ${employee.lastname}`,
                  }))}
                  onChange={(selected) => setSelectedEmployee(selected.value)}
                  components={{ DropdownIndicator }}
                />
              )}
            </div>
            {hasToSelectNewManager && (
              <Button
                variant="danger"
                size="sm"
                className="mt-2"
                onClick={attemptCloseAccount}
              >
                {t(`${tKey}companyDeletion.confirmButton`)}
              </Button>
            )}
          </Alert>
        </div>
      </div>
    </>
  );
};

export default DangerZone;
