import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Form from "../../../inputs/Form";
import ScheduleForm from "../../../inputs/ScheduleForm";
import { getListOptions } from "../../../../services/listService";
import { isValidPhoneNumber } from "react-phone-number-input";
import { formatSchedules } from "../../../../utils/formatting";

const Profile = ({
  tKey = "dashboard.settings.profile.",
  data = null,
  onFormDataChange,
}) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [formattedSchedules, setFormattedSchedules] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState({
    citiesList: [],
    categoriesList: [],
    servicesList: [],
  });

  const convertProfileSettingsToFormData = useCallback(
    (settings) => {
      setFormData({
        ...settings,
        restauranttype: {
          label: settings.restauranttype
            ? t(`utils.restauranttype.${settings.restauranttype.label}`)
            : null,
          value: settings.restauranttype || null,
        },
        city: {
          label: settings.city
            ? `${settings.city.npa} ${settings.city.label}`
            : null,
          value: settings.city || null,
        },
        consumptionmodes: settings.consumptionmodes
          ? settings.consumptionmodes.map((c) => {
              return { label: t(`utils.consumptionmode.${c.label}`), value: c };
            })
          : null,
      });
    },
    [t]
  );

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      convertProfileSettingsToFormData(data);
      setFormattedSchedules(formatSchedules(data.restaurantschedules));
    }
  }, [data, convertProfileSettingsToFormData]);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const citiesList = await getListOptions("city");
        const categoriesList = await getListOptions("restauranttype");
        const servicesList = await getListOptions("consumptionmode");
        setDropDownOptions({
          citiesList: citiesList.data,
          categoriesList: categoriesList.data,
          servicesList: servicesList.data,
        });
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
    };
    fetchListData();
  }, []);

  useEffect(() => {
    if (
      dropDownOptions.citiesList &&
      dropDownOptions.citiesList.length > 0 &&
      dropDownOptions.categoriesList &&
      dropDownOptions.categoriesList.length > 0 &&
      dropDownOptions.servicesList &&
      dropDownOptions.servicesList.length > 0
    )
      setFields([
        {
          name: "name",
          label: t(`${tKey}name.label`),
          placeholder: t(`${tKey}name.placeholder`),
          type: "text",
          class: "w-100",
          isLocked: true,
          validate: (value) => {
            if (!value.trim()) return t(`${tKey}errors.name.required`);
            if (value.length < 3) return t(`${tKey}errors.name.min`);
            if (value.length > 50) return t(`${tKey}errors.name.max`);
            return "";
          },
        },
        {
          name: "address",
          label: t(`${tKey}address.label`),
          placeholder: t(`${tKey}address.placeholder`),
          type: "text",
          class: "w-60 pr-2",
          validate: (value) => {
            if (!value.trim()) return t(`${tKey}errors.address.required`);
            if (value.length > 50) return t(`${tKey}errors.address.max`);
            return "";
          },
        },
        {
          name: "city",
          label: t(`${tKey}city.label`),
          placeholder: t(`${tKey}city.placeholder`),
          type: "dropdown",
          class: "w-40 pl-2",
          options: dropDownOptions.citiesList,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.city.required`);
            return "";
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `;
          },
        },
        {
          name: "restauranttype",
          label: t(`${tKey}restauranttype.label`),
          placeholder: t(`${tKey}restauranttype.placeholder`),
          type: "dropdown",
          class: "w-100",
          isLocked: true,
          options: dropDownOptions.categoriesList,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.restauranttype.required`);
            return "";
          },
          optionFormat: (value) => {
            return t(`utils.restauranttype.${value.label}`);
          },
        },
        {
          name: "consumptionmodes",
          label: t(`${tKey}consumptionmodes.label`),
          placeholder: t(`${tKey}consumptionmodes.placeholder`),
          type: "dropdown",
          multiple: true,
          class: "w-100",
          options: dropDownOptions.servicesList,
          validate: (value) => {
            if (!value.length)
              return t(`${tKey}errors.consumptionmodes.required`);
            return "";
          },
          optionFormat: (value) => {
            return t(`utils.consumptionmode.${value.label}`);
          },
        },
        {
          name: "phone",
          label: t(`${tKey}phone.label`),
          placeholder: t(`${tKey}phone.placeholder`),
          type: "phone",
          class: "w-100",
          isLocked: true,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.phone.required`);
            if (!isValidPhoneNumber(value))
              return t(`${tKey}errors.phone.invalid`);
            return "";
          },
        },
      ]);
  }, [dropDownOptions, t, tKey]);

  useEffect(() => {
    onFormDataChange(isValid ? formData : null);
  }, [isValid, formData, onFormDataChange]);

  const setFormSchedules = useCallback((schedules) => {
    setFormData((formData) => ({ ...formData, schedules }));
  }, []);

  return (
    <div className="dashboard-content h-100 flex gap-2">
      <div className="w-50 bg-white rounded p-3">
        <Form
          formGroupClass="mb-4"
          fields={fields}
          currentForm={formData}
          setFormDetails={setFormData}
          setIsValid={setIsValid}
        />
      </div>
      <div className="w-50 bg-white rounded p-3 table-container flex-grow">
        <div className="mb-2">{t(`${tKey}schedule.label`)}</div>
        <ScheduleForm
          data={formattedSchedules || null}
          onScheduleChange={setFormSchedules}
        />
      </div>
    </div>
  );
};

export default Profile;
