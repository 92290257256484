import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useApi from "../../hooks/useApi";
import { postConfirmAccount } from "../../services/authService";
import useLangNavigate from "../../hooks/useLangNavigate";
import useUserAgent from "../../hooks/useUserAgent";
import Button from "../../components/buttons/Button";

function ConfirmAccount() {
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const { activationCode } = useParams();
  const userAgent = useUserAgent();
  const { status, request: confirmAccountRequest } = useApi(postConfirmAccount);

  useEffect(() => {
    async function confirmAccount() {
      await confirmAccountRequest(activationCode)
        .then(() => {
          toast.success(t("confirmation.success"));
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
    confirmAccount();
  }, [activationCode, confirmAccountRequest, navigate, t]);

  const getLink = () => {
    if (!!userAgent)
      return (
        <Button
          variant="primary"
          onClick={() => {
            window.location.href = "offood://";
          }}
        >
          {t("confirmation.app")}
        </Button>
      );
    return (
      <Button validator="primary" onClick={() => navigate("/login")}>
        {t("home.login")}
      </Button>
    );
  };

  return (
    <div id="login">
      <div className="login-illustration" />
      <div className="login-content">
        <h4>{t("confirmation.title")}</h4>
        {status === 200 && (
          <>
            <h5>{t("confirmation.successTitle")}</h5>
            {getLink()}
          </>
        )}
        {status >= 300 && <h5>{t("confirmation.errorTitle")}</h5>}
      </div>
    </div>
  );
}

export default ConfirmAccount;
