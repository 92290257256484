import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLangNavigate from "../../hooks/useLangNavigate";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import {
  postCompanyForm,
  postRestaurantForm,
} from "../../services/authService";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/pro-light-svg-icons";
import { faArrowRight, faArrowLeft } from "@fortawesome/pro-thin-svg-icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../components/buttons/Button";
import Sidebar from "../../components/layout/Sidebar";
import StepEntity from "../../components/auth/register/StepEntity";
import StepCompany from "../../components/auth/register/StepCompany";
import StepRestaurantInfo from "../../components/auth/register/StepRestaurantInfo";
import StepRestaurantImage from "../../components/auth/register/StepRestaurantImage";
import StepRestaurantManager from "../../components/auth/register/StepRestaurantManager";
import StepRestaurantBank from "../../components/auth/register/StepRestaurantBank";
import StepRestaurantCredentials from "../../components/auth/register/StepRestaurantCredentials";
import StepRestaurantPricing from "../../components/auth/register/StepRestaurantPricing";
import Review from "../../components/auth/register/Review";
import ConfirmRegister from "../../components/auth/register/ConfirmRegister";
import { usePageTitle } from "../../hooks/useMeta";
import LangButton from "../../components/buttons/LangButton";

function Register({ tKey = "register." }) {
  usePageTitle("register");
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { referralCode } = useParams();
  const user = useSelector((state) => state.user);

  // API hooks
  const {
    error: companyFormError,
    status: companyFormStatus,
    loading: companyFormLoading,
    request: postCompanyFormRequest,
  } = useApi(postCompanyForm);
  const {
    error: restaurantFormError,
    status: restaurantFormStatus,
    loading: restaurantFormLoading,
    request: postRestaurantFormRequest,
  } = useApi(postRestaurantForm);

  // State
  const [currentStep, setCurrentStep] = useState(0);
  const [role, setRole] = useState(null);
  const [isStepValid, setIsStepValid] = useState(false);
  const [formData, setFormData] = useState({});
  const [currentPrice, setCurrentPrice] = useState(0);

  // Définir les étapes pour chaque flux
  const flowSteps = useMemo(() => {
    // Étapes pour un utilisateur non authentifié
    const newUserFlow = {
      company: [
        { key: "entity", tKey: `${tKey}step1.`, component: StepEntity },
        { key: "company", tKey: `${tKey}stepCompany.`, component: StepCompany },
        { key: "review", tKey: `${tKey}stepReview.`, component: Review },
      ],
      restaurant: [
        { key: "entity", tKey: `${tKey}step1.`, component: StepEntity },
        { key: "info", tKey: `${tKey}step2.`, component: StepRestaurantInfo },
        {
          key: "manager",
          tKey: `${tKey}step4.`,
          component: StepRestaurantManager,
        },
        { key: "image", tKey: `${tKey}step3.`, component: StepRestaurantImage },
        {
          key: "pricing",
          tKey: `${tKey}stepPricing.`,
          component: StepRestaurantPricing,
        },
        { key: "bank", tKey: `${tKey}step5.`, component: StepRestaurantBank },
        {
          key: "credentials",
          tKey: `${tKey}step6.`,
          component: StepRestaurantCredentials,
        },
        { key: "review", tKey: `${tKey}stepReview.`, component: Review },
      ],
    };

    // Étapes pour un utilisateur déjà authentifié
    const authenticatedUserFlow = {
      restaurant: [
        { key: "info", tKey: `${tKey}step2.`, component: StepRestaurantInfo },
        { key: "image", tKey: `${tKey}step3.`, component: StepRestaurantImage },
        {
          key: "pricing",
          tKey: `${tKey}stepPricing.`,
          component: StepRestaurantPricing,
        },
        { key: "bank", tKey: `${tKey}step5.`, component: StepRestaurantBank },
        { key: "review", tKey: `${tKey}stepReview.`, component: Review },
      ],
    };

    return isAuthenticated ? authenticatedUserFlow : newUserFlow;
  }, [isAuthenticated, tKey]);

  // Déterminer les étapes actuelles en fonction du rôle
  const currentFlowSteps = useMemo(() => {
    if (!role) return [];
    return flowSteps[role] || [];
  }, [role, flowSteps]);

  // Générer les éléments du menu pour la sidebar
  const menuList = useMemo(() => {
    if (!role && !isAuthenticated)
      return [{ tKey: `${tKey}step1.`, isActive: true }];

    return currentFlowSteps.map((step, index) => ({
      tKey: step.tKey,
      isActive: index === currentStep,
    }));
  }, [currentFlowSteps, currentStep, role, isAuthenticated, tKey]);

  // Gestionnaires d'événements
  const setFormDetails = useCallback((data) => {
    setFormData((prev) => ({ ...prev, ...data }));
  }, []);

  const setFormRole = useCallback((selectedRole) => {
    setRole(selectedRole);
    setFormData((prev) => ({ ...prev, role: selectedRole }));
    setCurrentStep(0); // Retour à la première étape du flux sélectionné
  }, []);

  const nextStep = useCallback(() => {
    if (currentStep < currentFlowSteps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      setIsStepValid(false);
    }
  }, [currentStep, currentFlowSteps]);

  const prevStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      setIsStepValid(false);
    } else if (currentStep === 0 && !isAuthenticated) setRole(null);
  }, [currentStep, isAuthenticated]);

  const registerUserAndEntity = async () => {
    try {
      const formPayload = {
        ...formData,
        referralCode: referralCode || null,
      };
      if (role === "company")
        await postCompanyFormRequest(formPayload, !isAuthenticated);
      else if (role === "restaurant")
        await postRestaurantFormRequest(formPayload, !isAuthenticated);
    } catch (error) {
      toast.error(error.message);
      console.error(`Failed to post ${role} form:`, error);
    }
  };

  useEffect(() => {
    if (companyFormError) toast.error(companyFormError.message);
    if (restaurantFormError) toast.error(restaurantFormError.message);
  }, [companyFormError, restaurantFormError]);

  useEffect(() => {
    if (restaurantFormStatus === 200 && user)
      setTimeout(() => {
        navigate("dashboard");
      }, 5000);
  }, [restaurantFormStatus, user, navigate]);

  // Préremplir les données utilisateur si authentifié
  useEffect(() => {
    if (isAuthenticated) {
      setFormRole("restaurant");
      setFormDetails({
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        function: user.occupiedFunction,
        ownerImage: user.imagePath,
        phone: user.phone,
      });
    }
  }, [isAuthenticated, user, setFormRole, setFormDetails]);

  useEffect(() => {
    if (currentFlowSteps[currentStep]?.key === "review") setIsStepValid(true);
  }, [currentStep, currentFlowSteps]);

  const renderCurrentStep = () => {
    if (
      companyFormStatus === 200 ||
      (restaurantFormStatus === 200 && !isAuthenticated)
    )
      return (
        <ConfirmRegister
          role={role}
          lastname={formData?.lastname}
          firstname={formData?.firstname}
          entityName={formData?.name}
        />
      );

    // Si aucun rôle n'est sélectionné, afficher la sélection de rôle
    if (!role && !isAuthenticated)
      return <StepEntity setRole={setFormRole} setIsValid={setIsStepValid} />;

    // Sinon, rendre le composant correspondant à l'étape actuelle
    if (currentFlowSteps.length > 0) {
      const StepComponent = currentFlowSteps[currentStep].component;
      const stepKey = currentFlowSteps[currentStep].key;

      // Props de base pour tous les composants d'étape
      const stepProps = {
        currentForm: formData,
        setIsValid: setIsStepValid,
      };

      // Ajouter des props spécifiques en fonction de l'étape
      const specificProps = {
        entity: {
          setRole: setFormRole,
          setFormData: setFormDetails,
        },
        company: {
          setCompanyDetails: setFormDetails,
        },
        info: {
          setRestaurantDetails: setFormDetails,
        },
        manager: {
          setOwnerDetails: setFormDetails,
        },
        image: {
          name: formData.name,
          restaurantType: formData.restaurantType?.value,
          setRestaurantPicture: setFormDetails,
        },
        pricing: {
          setPricingDetails: setFormDetails,
          setCurrentPrice: setCurrentPrice,
        },
        bank: {
          setBankDetails: setFormDetails,
        },
        credentials: {
          setCredentialsDetails: setFormDetails,
        },
        review: {
          role: role,
          formData: formData,
          setOwnerAvatar: (avatar) =>
            setFormData({ ...formData, ownerImage: avatar }),
        },
      };

      // Combiner les props de base avec les props spécifiques
      const combinedProps = {
        ...stepProps,
        ...(specificProps[stepKey] || {}),
      };

      return <StepComponent {...combinedProps} />;
    }

    return null;
  };

  // Déterminer le titre et la description pour la sidebar
  const getStepInfo = () => {
    if (companyFormStatus === 200 || restaurantFormStatus === 200)
      return {
        title: t(`${tKey}stepConfirm.header`),
        description: t(`${tKey}stepConfirm.description`),
      };

    if (!role && !isAuthenticated)
      return {
        title: t(`${tKey}step1.header`),
        description: t(`${tKey}step1.description`),
      };

    if (currentFlowSteps.length > 0) {
      const currentStepKey = currentFlowSteps[currentStep].tKey;
      return {
        title: t(`${currentStepKey}header`),
        description: t(`${currentStepKey}description`),
      };
    }

    return { title: "", description: "" };
  };

  const { title, description } = getStepInfo();
  const isLastStep = currentStep === currentFlowSteps.length - 1;
  const isFirstStep = currentStep === 0;
  const showBackButton = !isFirstStep || (isFirstStep && isAuthenticated);
  const isCurrentStepImage = currentFlowSteps[currentStep]?.key === "image";
  const isCurrentStepPricing = currentFlowSteps[currentStep]?.key === "pricing";

  return (
    <div className="sidebar-page-wrapper">
      <Sidebar
        menuList={menuList}
        isClickable={false}
        count={true}
        title={title}
        description={description}
        showLogout={false}
      />
      <div id="register" className="content">
        <div className="register-back flex justify-between">
          <div className="flex gap-2 align-center">
            <Button variant="tertiary" onClick={() => navigate("login")}>
              <FontAwesomeIcon size="xl" icon={faArrowLeftLong} />
            </Button>
            <LangButton />
          </div>
          <Button
            variant="info"
            onClick={() => navigate("support", { newTab: true })}
          >
            {t("utils.common.support")}
          </Button>
        </div>

        <div className="mt-4">{renderCurrentStep()}</div>

        {/* Navigation buttons */}
        {companyFormStatus !== 200 && restaurantFormStatus !== 200 && (
          <div className="btn-group mt-auto">
            {/* Back button */}
            {showBackButton && (
              <Button variant="primary" onClick={prevStep}>
                <FontAwesomeIcon
                  className="mr-2"
                  size="xl"
                  icon={faArrowLeft}
                />
                <span>{t(`register.previousStep`)}</span>
              </Button>
            )}

            {/* Next button */}
            {!isLastStep && (
              <Button
                variant="primary"
                disabled={!isStepValid && !isCurrentStepImage}
                onClick={nextStep}
              >
                <span>{t(`register.nextStep`)}</span>
                <FontAwesomeIcon
                  className="ml-2"
                  size="xl"
                  icon={faArrowRight}
                />
              </Button>
            )}

            {/* Skip button (only for image step) */}
            {isCurrentStepImage && (
              <Button variant="tertiary" onClick={nextStep}>
                {t(`register.skipStep`)}
              </Button>
            )}

            {/* Price display */}
            {isCurrentStepPricing && (
              <Button variant="danger">
                {t(`${tKey}stepPricing.total`, { price: currentPrice })}
              </Button>
            )}

            {/* Submit button */}
            {isLastStep && (
              <Button
                variant="success"
                onClick={registerUserAndEntity}
                loading={companyFormLoading || restaurantFormLoading}
                disabled={!isStepValid}
              >
                {t(`register.${role}Submit`)}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
